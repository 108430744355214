import type { JSONContent } from '@tiptap/react'
import type { Dictionary, Market, Tickers } from 'ccxt'
import { v4 as uuid } from 'uuid'
import MiPasaCellEmbedConnection from '~api/mipasa/connections/cell-embed'
import MiPasaCollaboratorLobbyConnection from '~api/mipasa/connections/collaborator-lobby'
import MiPasaNotebookConnection from '~api/mipasa/connections/notebook'
import MiPasaNotificationsConnection from '~api/mipasa/connections/notifications'
import MiPasaProjectImportConnection from '~api/mipasa/connections/project-import'
import MiPasaTrackingConnection from '~api/mipasa/connections/tracking'
import MiPasaTradingTransactionsConnection from '~api/mipasa/connections/trading-transactions'
import { autodetectMimeType } from '~components/code/ImagePreview/ImagePreview.utils'
import type { NotebookStrategyAsset } from '~components/code/StrategyEditor/StrategyEditor.types'
import { getFileCodingLanguageRecord } from '~components/code/file-explorer/explorer-utils'
import { CodingType, type CodingLanguage } from '~components/code/type'
import type { OpenAPISpec } from '~components/openapi/OpenAPI.types'
import type { Profile } from '~components/profile/slice'
import type { ExchangeType } from '~data/exchanges'
import type { Execution, ExecutionSummary } from '~data/execution/execution-type'
import type { File, FileId, FileVersion } from '~data/file/file-type'
import { ViewMode } from '~data/listing/listing.types'
import type { Project, ProjectBasicInfo, ProjectPermission, ProjectPortfolioAsset, ProjectPortfolioMeta, ProjectSyncConfig } from '~data/projects'
import { CURRENT_PLATFORM } from '~routes/platform'
import assertUnreachable from '~utils/assertUnreachable'
import { HTTPMethods, authorizedFetch, authorizedJsonFetch, authorizedTextFetch } from '~utils/authorized-fetch'
import { getFileParentPath } from '~utils/file'
import { captureError } from '~utils/monitoring'
import type {
  AIGenerateSQLOpts,
  AIGeneratedCode,
  AIGeneratedEntry,
  AIGenerationType,
  AdminActivityLog,
  AdminFeature,
  AdminFetchActivityOptions,
  AdminFetchSubStats,
  AdminFetchUserActionLogsOption,
  AdminFetchUsersOptions,
  AdminRole,
  AdminSubscriptionStatsResult,
  AdminSubscriptionsStat,
  AdminUser,
  AdminUserActionLog,
  AdminWalletTransaction,
  AdminWalletTransactionParams,
  AdminWatermarkTheme,
  AppStatus,
  Backend,
  BackendCellEmbedConnection,
  BackendCellEmbedConnectionMode,
  BackendCollaborationConnection,
  BackendCollaboratorLobbyConnection,
  BackendContainerConnection,
  BackendExecutionConnection,
  BackendFileChannelConnection,
  BackendNotebookConnection,
  BackendNotebookConnectionParameters,
  BackendNotificationsConnection,
  BackendPermissionInfo,
  BackendProjectImportConnection,
  BackendSelectInfo,
  BackendSlackChannel,
  BackendSortingInfo,
  BackendSortingOption,
  BackendTrackingConnection,
  BackendTradingTransactionsConnection,
  Balance,
  BinanceConnection,
  BrowseSyncRemoteParams,
  BrowseSyncRemoteResponse,
  CreateExistingProjectSyncConfigParams,
  CreateNewProjectSyncConfigParams,
  CreateProjectFileOptions,
  CreateProjectSyncConfigParams,
  CursorPaginatedResponse,
  DocumentationSection,
  DocumentationSectionUpdate,
  EventData,
  EventEntry,
  ExportProjectFileOpts,
  ExternalPublishSettings,
  FeedEntry,
  FetchExecutionsOptions,
  FetchMembersPaginationOptions,
  FetchPermissionsPaginationOptions,
  FetchProjectFileOptions,
  FetchSelfOptions,
  FetchSlackChannelsParams,
  FetchTeamsPaginationOptions,
  FetchWalletPaginationOptions,
  FileEmbedOptions,
  FileUploadItem,
  FileUploadParams,
  GitHubConnection,
  InstanceExportOpts,
  Invite,
  InviteLocation,
  NewsroomEntry,
  NewsroomEntryUpdate,
  NotebookAnalysis,
  NotebookAnalysisDirection,
  NotebookAnalysisNode,
  NotebookComment,
  NotebookCommentContent,
  NotebookCommentReaction,
  NotebookPermission,
  NotificationEntry,
  NotificationsParams,
  PaginatedResult,
  PaginationOptions,
  ProjectAccessRequest,
  ProjectDirectoryMetadata,
  ProjectFileMetadata,
  ProjectInvite,
  ProjectPaginatedResult,
  ProjectPaginationOptions,
  ProjectSyncResponse,
  ProjectTeamAccess,
  ProjectUserAccess,
  Publication,
  PublicationFetchParams,
  PublicationPaginatedResult,
  PublicationPaginationOptions,
  PublicationSchedule,
  PublicationStatName,
  PublicationType,
  PublicationUpdateParams,
  RatePlan,
  RatePlanFeatures,
  RatePlanName,
  ReferralUser,
  ResponseCollection,
  ScheduledExecution,
  ScheduledExecutionPatch,
  ScheduledNotebook,
  ScheduledNotebookListingOptions,
  Secret,
  Self,
  SignupStat,
  Subscription,
  SubscriptionStats,
  SyncProjectOpts,
  Tag,
  TagSubscription,
  Team,
  TeamCreateDraft,
  TeamDraft,
  TeamInvite,
  TeamInviteRequest,
  TeamJoinRequest,
  TeamJoinRequestStatus,
  TeamMember,
  TeamMemberRequest,
  TeamMembershipStatus,
  TeamProject,
  Tip,
  TradingAsset,
  TradingAssetChartOpts,
  TradingAssetData,
  TradingAssetDetailsOpts,
  TradingAssetsOpts,
  TradingBalance,
  TradingBotChannel,
  TradingExchangeOpts,
  TradingTransaction,
  UploadProjectParams,
  UserAboutPage,
  UserFollow,
  UserInfo,
  UserPreferences,
  UserServer,
  UserToken,
  WalletBalances,
  WalletState,
  WalletTransaction,
  WalletTransactionKind,
  WalletTransactionType,
  WalletUnitPurchaseInfo,
  WalletUnitPurchaseSession,
} from '../common/backend'
import {
  AIGeneratedPublicationsQuery,
  AdminUserRole,
  ExportFormat,
  ExportTheme,
  Feature,
  FileEmbed,
  InviteLocationType,
  NotebookAnalysisNodeType,
  PublicationScheduleType,
  PublicationsAccessLevel,
  Referral,
  ReferralUpdate,
  TeamDiscovery,
  TeamMemberPermission,
} from '../common/backend'
import MiPasaFileChannelConnection from './connections/file-channel'

interface MiPasaUser {
  id: string
  avatar?: string
  avatar_icon?: string
  first_name: string
  last_name: string
  link: string
  email: string
  email_is_confirmed: boolean
  user_name: string
  admin?: boolean
  headline: string | null
  social: {
    blog_url: string | null
    facebook_url: string | null
    linkedin_url: string | null
    twitter_url: string | null
    website_url: string | null
  }
  company: {
    name: string | null
    position: string | null
  }
  following_by_you?: boolean
  followers_count: number
  is_discoverable: boolean
  is_suspended: boolean
  referral_code: string
  is_idm_managed?: boolean
  is_telegram_managed?: boolean
}

export interface MiPasaPaginatedResult<T> {
  entries: T[]
  page_number: number
  page_size: number
  total_entries: number
  total_pages: number
}

interface MiPasaNotebookPaginatedResult extends MiPasaPaginatedResult<MiPasaNotebook> {
  tags: Tag[]
}

interface MiPasaPublicationsPaginatedResult extends MiPasaPaginatedResult<MiPasaPublication> {
  tags: Tag[]
}

interface MiPasaProjectSyncConfig {
  id: string
  provider: string
  repo: string
  branch: string
  directory?: string
  synced_at: string
  synced_version: string
}

interface MiPasaProjectSyncResponse {
  local_changes: string[]
  remote_changes: string[]
  sync_config: MiPasaProjectSyncConfig
}

interface MiPasaNotebookBase {
  id: string
  description: string | null
  analyze_count: number
  clone_count: number
  comment_count: number
  execute_count: number
  follow_count: number
  share_count: number
  view_count: number

  created_at: string
  created_by: MiPasaUser

  owned_at: string
  owned_by: MiPasaUser

  license: string | null
  name: string
  language: CodingLanguage | null
  section: string

  published_at: string
  is_public: boolean
  public_url: string
  public_url_slug?: string | null
  parent_id: string

  setup_complete: boolean

  tags: Array<Tag>
  thumbnail: {
    icon_url?: string
    original_url?: string
  }

  updated_at: string

  cells?: Array<unknown>

  default_file_id?: string
}

interface MiPasaProjectPortfolioMeta {
  assets: Array<ProjectPortfolioAsset>
  trade_asset?: string
  trade_asset_icon?: string
  holdings: Record<string, number>
  total_deposit: number
  total_withdrawal: number
  exchange?: ExchangeType
}

interface MiPasaNotebook extends MiPasaNotebookBase {
  is_published?: boolean
  permissions?: Record<string, boolean>
  published_clone_id?: string | null
  authors?: MiPasaUser[]
  sync_config?: MiPasaProjectSyncConfig
  portfolio_meta?: MiPasaProjectPortfolioMeta
  latest_ok_version_id?: string
  latest_version_id?: string
}

interface MiPasaTeamNotebook extends MiPasaNotebook {
  team_permission_id: string
}

interface MiPasaSubscription {
  id: string
  expiration_date: string | null
  next_billing_date: string | null
  canceled_at: string | null
  rate_plan: MiPasaRatePlan
}

interface MiPasaRatePlan {
  id: string
  is_highlighted: boolean
  price_in_units: number
  title: RatePlanName
  details: string
  features: RatePlanFeatures
}

interface MiPasaDirectoryEntry {
  basename: string
  type: 'directory'
  metadata: ProjectDirectoryMetadata
}

interface MiPasaFileEntry {
  basename: string
  type: 'file'
  file: MiPasaFileWithContent
}

interface MiPasaFileInfo {
  id: string
  name: string
  language: string
  mime_type: string
  metadata: ProjectFileMetadata
  created_at: string
}

export type MiPasaFileFormat = 'base64' | 'text'
interface MiPasaFileUpdateInfo {
  content: string
  format: MiPasaFileFormat
  language: MiPasaFileInfo['language']
  mime_type: MiPasaFileInfo['mime_type']
  name: MiPasaFileInfo['name']
}

type MiPasaFileWithContent = MiPasaFileInfo & MiPasaFileUpdateInfo

export interface MiPasaFileVersion {
  id: string
  name: string | null
  created_at: string
  revisioned_at: string | null
  file: MiPasaFileWithContent
  payload: string
}

interface MiPasaUserAboutPage {
  content_rich_draft?: JSONContent | null
  content_rich: JSONContent | null
}

interface MiPasaSelf {
  api_key: string
  id: string
  token: string
  user: MiPasaUser
  masked?: boolean
  github?: MiPasaGitHubConnection
  slack: MiPasaSlackConnection | null
  preferences?: UserPreferences
  features?: Feature[]
}

interface MiPasaBalance {
  user_id: string
  balance: number
}

interface MiPasaNotebookRun {
  ended_at: string | null
  started_at: string
  started_by: MiPasaUser
  environment: string
  execution_type: 'on_demand' | 'scheduled'
  id: string
  number: number
  status: 'in_progress' | 'ok' | 'error' | 'aborted'
  version?: {
    id: string
    name: string
  }
  file?: {
    id: string
    name: string
  }
}

interface MiPasaUserPermission {
  id: string
  permissions: string[]
  user: MiPasaUser
  author: boolean
  created_at: string
  is_owner: boolean
}

interface MiPasaTeamPermission {
  id: string
  permissions: string[]
  team: MiPasaTeam
  created_at: string
}

interface MiPasaEventEntry {
  type: string
  resource_id: string
}

interface MiPasaUpload {
  id: string
  url: string
  method: HTTPMethods
}

interface MiPasaTeam {
  id: string
  name: string
  created_at: string
  updated_at: string
  created_by: MiPasaUser
  is_discoverable: boolean
  approvable_join: boolean
  hidden: Array<string>
  thumbnail: {
    icon_url?: string | null
    original_url?: string | null
  }
  permissions: Record<string, boolean>
  membership_status: TeamMembershipStatus
  contact_email?: string | null
  description?: string | null
  users_count?: number | null
  projects_count?: number | null
  last_members?: Array<{ user: MiPasaUser; permissions: Array<'manage' | 'view'> }>
}

interface MiPasaTeamMemberRequest {
  user_id: string
  permissions: Array<TeamMemberPermission>
}

interface MiPasaTeamDraft {
  name: string
  description?: string
  contact_email?: string
  is_discoverable?: boolean
  approvable_join?: boolean
  members?: Array<MiPasaTeamMemberRequest>
  invites?: Array<TeamInviteRequest>
}

interface MiPasaTeamMember {
  id: string
  permissions: Array<TeamMemberPermission>
  user: MiPasaUser
  created_at: string
}

interface MiPasaTeamJoinRequest {
  id: string
  team_id: string
  created_at: string
  status: TeamJoinRequestStatus
  user: MiPasaUser
  reason?: string
}

interface MiPasaProjectAccessRequest {
  id: string
  user: MiPasaUser
  created_at: string
  reason: string
  status: string
}

interface MiPasaProjectInvite {
  id: string
  permissions: Array<NotebookPermission>
  email: string
  created_at: string
}

interface MiPasaFeedEntry {
  id: string
  action: string
  target: {
    id: string
    type: string
    name: string
  }
  performed_at: string
  user: MiPasaUser
  performed_by: MiPasaUser
}

interface MiPasaTeamInvite {
  id: string
  permissions: Array<TeamMemberPermission>
  email: string
  created_at: string
}

interface MiPasaGenericInviteLocation {
  type: 'generic'
}

interface MiPasaTeamInviteLocation {
  type: 'single_team'
  id: string
}

interface MiPasaProjectInviteLocation {
  type: 'single_notebook'
  id: string
}

type MiPasaInviteLocation = MiPasaGenericInviteLocation | MiPasaTeamInviteLocation | MiPasaProjectInviteLocation

interface MiPasaInvite {
  id: string
  email: string
  created_at: string
  to: MiPasaInviteLocation
}

interface MiPasaNotebookComment {
  author: MiPasaUser
  created_at: string
  id: string
  reaction_by_you: NotebookCommentReaction
  likes_count: number
  dislikes_count: number
  reply_to: string
  content: NotebookCommentContent
  replies?: NotebookComment[]
  is_deleted: boolean
}

interface MiPasaUserFollow {
  follower: MiPasaUser
  following_by_you: boolean
}

interface MiPasaGitHubConnection {
  is_connected: boolean
  user_name: string | null
  avatar: string | null
}

interface MiPasaBinanceConnection {
  is_connected: boolean
}

interface MiPasaSlackConnection {
  team_name: string | null
}

interface MiPasaNotificationEntryTeam {
  id: string
  name: string
}

export interface MiPasaNotificationEntry {
  type: string
  created_at: string
  id: string
  metadata: Record<string, string>
  entity?: {
    id: string
    type: string
    name: string
    link: string
    slug: string
  }
  seen_at: string | null
  source_user?: MiPasaUser
  subject_user?: MiPasaUser
  subject_team?: MiPasaNotificationEntryTeam
}

export interface MiPasaPublication {
  id: string
  project_id: string
  file_id: string
  version_id: string
  type: PublicationType
  access_level: PublicationsAccessLevel
  published_at: string
  published_changes_at: string
  slug: string
  title: string
  description: string | null
  cover_image: string | null
  view_count: number
  share_count: number
  tags: Array<{ id: string; name: string }>
  payload?: string
  pasa_info?: {
    openapi_spec: OpenAPISpec
    portfolio_assets?: Array<NotebookStrategyAsset>
    portfolio_1d?: number
    portfolio_7d?: number
    portfolio_30d?: number
    portfolio_max?: number
    portfolio_sharpe_ratio?: number
    portfolio_1d_chart?: Array<{ date: string; value: number }>
    portfolio_7d_chart?: Array<{ date: string; value: number }>
    portfolio_30d_chart?: Array<{ date: string; value: number }>
    portfolio_max_chart?: Array<{ date: string; value: number }>
    portfolio_1d_days?: number
    portfolio_7d_days?: number
    portfolio_30d_days?: number
    portfolio_max_days?: number
    portfolio_asset_categories?: Record<string, Array<string>>
    portfolio_asset_prices?: Record<string, number>
  }
  portfolios?: Array<ProjectBasicInfo>
  authors: Array<MiPasaUser>
  claps_count?: number
  clapped_by_you?: boolean
  owner: MiPasaUser
  external_providers: Publication['externalProviders']
  permissions?: Record<string, boolean>
  execution_enabled: boolean
  execution_copy_project_files: boolean
}

interface MiPasaExternalPublishSettings {
  enabled: boolean
  cell_ids: string[]
  title?: string
  print_format: ExportFormat
  print_theme: ExportTheme
  print_width: number
  layout_header_text: string | null
  layout_header_date: boolean
  layout_footer_text: string | null
  slack_settings?: { enabled: boolean; channel_name?: string; channel_id?: string }
  email_settings?: { enabled: boolean; emails?: string[] }
}

interface MiPasaFileEmbedOptions {
  execution_enabled: boolean
  execution_copy_project_files: boolean
}

interface MiPasaFileEmbed extends MiPasaFileEmbedOptions {
  embed_id: string | null
  project_id: string | null
  project_file_id: string | null
}

interface MiPasaPublicationSchedule {
  type: 'one_off' | 'recurring'
  enabled?: boolean
  starts_at?: string
  next_run_at?: string
  last_run_at?: string
  interval_type?: 'minute' | 'hour' | 'day' | 'week' | 'month'
  interval_value?: number
  allowed_days?: number[]
}

interface AdminMiPasaUser {
  id: string
  name: string
  email: string
  user_name: string
  signup_date: string
  active_at?: string
  balance: number
  role: 'user' | 'admin'
  roles: AdminRole[]
  features: Feature[]
  subscription: null | {
    id: string
    title: string
  }
  is_suspended: boolean
  avatar?: string
  avatar_icon?: string
}

interface AdminMiPasaTransaction {
  id: string
  type: WalletTransactionType
  kind: WalletTransactionKind
  amount_in_units: number
  inserted_at: string
}

interface AdminMiPasaActionLog {
  id: string
  inserted_at: string
  action: 'suspend' | 'unsuspend'
  user: AdminMiPasaUser
  admin: AdminMiPasaUser
  metadata: Record<string, unknown>
}

interface AdminMiPasaActivityLog {
  id: string
  inserted_at: string
  type: string
  user?: AdminMiPasaUser
  metadata: Record<string, unknown>
}

interface MiPasaSlackChannel {
  id: string
  name: string
  is_private: boolean
}

interface MiPasaBackendSlackChannelsResponse {
  entries: MiPasaSlackChannel[]
  next_cursor: string
}

interface MiPasaTip {
  id: string
  name: string
  description: string | null
  thumbnail: {
    icon_url?: string
    original_url?: string
  }
}

export interface MiPasaTradingAsset {
  remote_type: string
  remote_id: string
  symbol: string
  description: string
  image_url?: string | null
  market_cap?: number
  price?: number
  age?: number
}

export interface MiPasaTradingAssetData {
  market_caps: Array<[number, number]>
  prices: Array<[number, number]>
  total_volumes: Array<[number, number]>
}

interface MiPasaSubscriptionBaseStat {
  strategy_id: string
  strategy_title: string
  strategy_slug: string
  strategy_image?: string
}

interface MiPasaSubscriptionView extends MiPasaSubscriptionBaseStat {
  views: number
}

interface MiPasaSubscriptionSub extends MiPasaSubscriptionBaseStat {
  subscriptions: number
}

export interface MiPasaSubscriptionStats {
  views: MiPasaSubscriptionView[]
  subscriptions: MiPasaSubscriptionSub[]
}

export interface MiPasaSignupStat {
  id: string
  first_name: string
  last_name: string
  user_name: string
}

export interface MiPasaAdminSubscriptionsStat {
  user_id: string
  user_name: string
  user_first_name: string
  user_last_name: string
  user_referral_code: string
  user_email: string
  views: number
  subscriptions: number
}

export interface MiPasaAdminSubscriptionStatsResult extends MiPasaPaginatedResult<MiPasaAdminSubscriptionsStat> {
  total_views: number
  total_subs: number
}

interface MiPasaAdminReferralUser {
  id: string
  first_name?: string
  last_name?: string
  user_name: string
  inserted_at: string
}

function mapAdminSubscriptionStat(mipasaStat: MiPasaAdminSubscriptionsStat): AdminSubscriptionsStat {
  return {
    userId: mipasaStat.user_id,
    userName: mipasaStat.user_name,
    userFirstName: mipasaStat.user_first_name,
    userLastName: mipasaStat.user_last_name,
    userReferralCode: mipasaStat.user_referral_code,
    userEmail: mipasaStat.user_email,
    views: mipasaStat.views,
    subscriptions: mipasaStat.subscriptions,
  }
}

function mapSignupStat(mipasaStat: MiPasaSignupStat): SignupStat {
  return {
    id: mipasaStat.id,
    firstName: mipasaStat.first_name,
    lastName: mipasaStat.last_name,
    userName: mipasaStat.user_name,
  }
}

function mapSubscriptionStats(mipasaStats: MiPasaSubscriptionStats): SubscriptionStats {
  const views = mipasaStats.views.map(v => ({
    strategyId: v.strategy_id,
    strategyTitle: v.strategy_title,
    strategySlug: v.strategy_slug,
    strategyImage: v.strategy_image,
    views: v.views,
  }))

  const subscriptions = mipasaStats.subscriptions.map(v => ({
    strategyId: v.strategy_id,
    strategyTitle: v.strategy_title,
    strategySlug: v.strategy_slug,
    subscriptions: v.subscriptions,
  }))

  return { views, subscriptions }
}

function mapExternalPublicationSettings(mipasaSettings: MiPasaExternalPublishSettings): ExternalPublishSettings {
  return {
    enabled: mipasaSettings.enabled,
    cellIds: mipasaSettings.cell_ids,
    title: mipasaSettings.title,
    printFormat: mipasaSettings.print_format,
    printTheme: mipasaSettings.print_theme,
    printWidth: mipasaSettings.print_width,
    layoutHeaderText: mipasaSettings.layout_header_text,
    layoutHeaderDate: mipasaSettings.layout_header_date,
    layoutFooterText: mipasaSettings.layout_footer_text,
    slackSettings: {
      enabled: Boolean(mipasaSettings.slack_settings?.enabled),
      channelId: mipasaSettings.slack_settings?.channel_id,
      channelName: mipasaSettings.slack_settings?.channel_name,
    },
    emailSettings: {
      enabled: Boolean(mipasaSettings.email_settings?.enabled),
      emails: mipasaSettings.email_settings?.emails,
    },
  }
}

function mapFileEmbed(miPasaFileEmbed: MiPasaFileEmbed): FileEmbed {
  return {
    id: miPasaFileEmbed.embed_id ?? undefined,
    projectId: miPasaFileEmbed.project_id ?? undefined,
    projectFileId: miPasaFileEmbed.project_file_id ?? undefined,
    isExecutionEnabled: miPasaFileEmbed.execution_enabled,
    isFileCopyEnabled: miPasaFileEmbed.execution_copy_project_files,
  }
}

function mapPublicationSchedule(mipasaSchedule: MiPasaPublicationSchedule): PublicationSchedule {
  const t = mipasaSchedule.type === 'recurring' ? PublicationScheduleType.recurring : PublicationScheduleType.oneOff
  const it = mipasaSchedule.interval_type ?? (t === PublicationScheduleType.recurring ? 'hour' : undefined)
  const iv = mipasaSchedule.interval_value ?? (t === PublicationScheduleType.recurring ? 1 : undefined)

  return {
    type: t,
    enabled: mipasaSchedule.enabled,
    startsAt: mipasaSchedule.starts_at ? new Date(mipasaSchedule.starts_at) : undefined,
    lastRunAt: mipasaSchedule.last_run_at ? new Date(mipasaSchedule.last_run_at) : undefined,
    nextRunAt: mipasaSchedule.next_run_at ? new Date(mipasaSchedule.next_run_at) : undefined,
    intervalType: it,
    intervalValue: iv,
    allowedDays: mipasaSchedule.allowed_days,
  }
}

function mapMiPasaTip(mitip: MiPasaTip): Tip {
  return {
    id: mitip.id,
    name: mitip.name,
    description: mitip.description,
    preview: mitip.thumbnail.icon_url || null,
  }
}

function mapSlackChannel(ch: MiPasaSlackChannel): BackendSlackChannel {
  return {
    id: ch.id,
    name: ch.name,
    isPrivate: ch.is_private,
  }
}

function mapPaginatedResponse<T, U>(page: MiPasaPaginatedResult<T>, cb: (a: T) => U): PaginatedResult<U> {
  return {
    page: page.page_number,
    perPage: page.page_size,
    totalPages: page.total_pages,
    totalEntries: page.total_entries,
    entries: page.entries.map(cb),
  }
}

function mapAdminActivityLogMetadata(log: AdminMiPasaActivityLog): AdminActivityLog['metadata'] {
  if (log.type === 'notebook.ownership_changed') {
    return {
      ...log.metadata,
      new_owner: log.metadata.new_owner ? mapAdminUser(log.metadata.new_owner as AdminMiPasaUser) : undefined,
      previous_owner: log.metadata.previous_owner ? mapAdminUser(log.metadata.previous_owner as AdminMiPasaUser) : undefined,
    }
  }

  if (log.type === 'notebook.permissions_changed') {
    const newSubject = { ...(log.metadata.subject as any) }

    if (newSubject?.type === 'user' && newSubject?.data) {
      newSubject.data = mapAdminUser(newSubject.data)
    }

    return {
      ...log.metadata,
      subject: newSubject,
    }
  }

  if (log.type === 'publication.viewed') {
    const newReferral = log.metadata.referral as AdminMiPasaUser | undefined

    return {
      ...log.metadata,
      referral: newReferral ? mapAdminUser(newReferral) : undefined,
    }
  }

  return log.metadata as AdminActivityLog['metadata']
}

function mapAdminActivityLog(log: AdminMiPasaActivityLog): AdminActivityLog {
  return {
    id: log.id,
    type: log.type as AdminActivityLog['type'],
    insertedAt: new Date(`${log.inserted_at}Z`),
    user: log.user ? mapAdminUser(log.user) : undefined,
    metadata: mapAdminActivityLogMetadata(log),
  } as AdminActivityLog
}

function mapAdminActionLog(log: AdminMiPasaActionLog): AdminUserActionLog {
  return {
    id: log.id,
    action: log.action,
    insertedAt: new Date(log.inserted_at),
    user: mapAdminUser(log.user),
    admin: mapAdminUser(log.admin),
    metadata: log.metadata,
  }
}

function mapAdminTransaction(trans: AdminMiPasaTransaction): AdminWalletTransaction {
  return {
    id: trans.id,
    type: trans.type,
    kind: trans.kind,
    amount: trans.amount_in_units,
    insertedAt: new Date(trans.inserted_at),
  }
}

function mapRole(role: AdminMiPasaUser['role']): AdminUser['role'] {
  switch (role) {
    case 'user':
      return AdminUserRole.user
    case 'admin':
      return AdminUserRole.admin
    default:
      throw new Error(`Role ${role} not recorgnized`)
  }
}

function mapAdminUser(user: AdminMiPasaUser): AdminUser {
  return {
    id: user.id,
    name: user.name,
    email: user.email,
    userName: user.user_name,
    signupDate: new Date(user.signup_date),
    activeAt: user.active_at ? new Date(user.active_at) : undefined,
    balance: user.balance,
    role: mapRole(user.role),
    roles: user.roles,
    features: user.features,
    subscription: user.subscription,
    isSuspended: user.is_suspended,
    avatar: user.avatar,
    avatarIcon: user.avatar_icon,
  }
}

function mapUser(user: MiPasaUser): UserInfo {
  const actualUsername = user.user_name || user.link.split('/').pop() || 'unknown'

  return {
    id: user.id,
    headline: user.headline || undefined,
    username: actualUsername,
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    avatar: user.avatar,
    avatarIcon: user.avatar_icon,
    link: user.link,
    followingByYou: user.following_by_you,
    followersCount: user.followers_count,
    isDiscoverable: user.is_discoverable,
    referralCode: user.referral_code,
    isIDMManaged: user.is_idm_managed,
    isTelegramManaged: user.is_telegram_managed,
  }
}

function mapImageURL(url: string, urlPrefix: string): string {
  if (url.startsWith('/') && !url.startsWith('//')) {
    return `${urlPrefix}${url}`
  }

  return url
}

function mapPublication(mipasaPublication: MiPasaPublication, urlPrefix: string): Publication {
  return {
    id: mipasaPublication.id,
    projectId: mipasaPublication.project_id,
    fileId: mipasaPublication.file_id,
    versionId: mipasaPublication.version_id,
    type: mipasaPublication.type,
    accessLevel: mipasaPublication.access_level,
    publishedAt: mipasaPublication.published_at,
    publishedChangesAt: mipasaPublication.published_changes_at || mipasaPublication.published_at,
    slug: mipasaPublication.slug,
    title: mipasaPublication.title,
    description: mipasaPublication.description || undefined,
    coverImage: mipasaPublication.cover_image ? mapImageURL(mipasaPublication.cover_image, urlPrefix) : undefined,
    viewCount: mipasaPublication.view_count,
    shareCount: mipasaPublication.share_count,
    tags: mipasaPublication.tags,
    payload: mipasaPublication.payload,
    pasaInfo: mipasaPublication.pasa_info && {
      openApiSpec: mipasaPublication.pasa_info.openapi_spec,
      portfolioAssets: mipasaPublication.pasa_info.portfolio_assets,
      portfolio1D: mipasaPublication.pasa_info.portfolio_1d,
      portfolio7D: mipasaPublication.pasa_info.portfolio_7d,
      portfolio30D: mipasaPublication.pasa_info.portfolio_30d,
      portfolioMax: mipasaPublication.pasa_info.portfolio_max,
      portfolioSharpeRatio: mipasaPublication.pasa_info.portfolio_sharpe_ratio,
      portfolio1DChart: mipasaPublication.pasa_info.portfolio_1d_chart,
      portfolio7DChart: mipasaPublication.pasa_info.portfolio_7d_chart,
      portfolio30DChart: mipasaPublication.pasa_info.portfolio_30d_chart,
      portfolioMaxChart: mipasaPublication.pasa_info.portfolio_max_chart,
      portfolio1DDays: mipasaPublication.pasa_info.portfolio_1d_days,
      portfolio7DDays: mipasaPublication.pasa_info.portfolio_7d_days,
      portfolio30DDays: mipasaPublication.pasa_info.portfolio_30d_days,
      portfolioMaxDays: mipasaPublication.pasa_info.portfolio_max_days,
      portfolioAssetCategories: mipasaPublication.pasa_info.portfolio_asset_categories,
      portfolioAssetPrices: mipasaPublication.pasa_info.portfolio_asset_prices,
    },
    portfolios: mipasaPublication.portfolios,
    authors: mipasaPublication.authors.map(mapUser),
    clapsCount: mipasaPublication.claps_count,
    clappedByYou: mipasaPublication.clapped_by_you ?? false,
    owner: mapUser(mipasaPublication.owner),
    externalProviders: mipasaPublication.external_providers,
    permissions: mipasaPublication.permissions ? mapPermissions(mipasaPublication.permissions) : undefined,
    executionEnabled: mipasaPublication.execution_enabled,
    executionCopyProjectFiles: mipasaPublication.execution_copy_project_files,
  }
}

interface MiPasaDocumentationSection {
  id: string
  index: number
  slug: string
  publication: MiPasaPublication
}

function mapDocumentationSection(section: MiPasaDocumentationSection, urlPrefix: string) {
  return {
    id: section.id,
    index: section.index,
    slug: section.slug,
    publication: mapPublication(section.publication, urlPrefix),
  }
}

function mapPermissions(miPasaPermissions: Record<string, boolean>): Array<ProjectPermission> {
  const permissions = Object.getOwnPropertyNames(miPasaPermissions).filter(k => miPasaPermissions[k]) as Array<ProjectPermission>

  permissions.push('view')
  return [...new Set(permissions)]
}

function mapNotebookBase(notebook: MiPasaNotebookBase | MiPasaNotebook, mode?: ViewMode, selfId?: string): Project {
  let isTeamShared = false

  if ('permissions' in notebook && notebook.permissions) {
    isTeamShared = notebook.owned_by.id !== selfId && Object.values(notebook.permissions).some(p => p)
  }

  return {
    id: notebook.id,
    name: notebook.name,
    description: notebook.description || '',
    section: notebook.section,
    language: notebook.language || 'python',
    isPublic: notebook.is_public,
    teamShared: mode === ViewMode.shared || isTeamShared,
    parentId: notebook.parent_id,
    image: notebook.thumbnail?.icon_url,
    preview: notebook.thumbnail?.icon_url || '',
    publicGroup: mode === ViewMode.public ? 'all' : 'teams',
    publicUrl: notebook.public_url_slug || undefined,
    userId: notebook.owned_by.id,
    createdAt: new Date(notebook.created_at).getTime(),
    updatedAt: new Date(notebook.updated_at).getTime(),
    publishedAt: new Date(notebook.published_at).getTime(),
    ownedAt: new Date(notebook.owned_at).getTime(),
    githubRepo: undefined,
    githubBranch: undefined,
    totalRating: 0,
    settings: undefined,
    tags: notebook.tags || [],
    user: mapUser(notebook.owned_by),
    creator: mapUser(notebook.created_by),
    isSetupComplete: notebook.setup_complete,
    stats: {
      analyze: notebook.analyze_count,
      clone: notebook.clone_count,
      comment: notebook.comment_count,
      execute: notebook.execute_count,
      follow: notebook.follow_count,
      share: notebook.share_count,
      view: notebook.view_count,
    },
    license: notebook.license,
    defaultFileId: notebook.default_file_id,
  }
}

function mapNotebook(notebook: MiPasaNotebook, mode?: ViewMode, selfId?: string): Project {
  return {
    ...mapNotebookBase(notebook, mode, selfId),
    permissions: mapPermissions(notebook.permissions || {}),
    isPublished: notebook.is_published,
    publishedCloneId: notebook.published_clone_id || undefined,
    authors: (notebook.authors || []).map(mapUser),
    syncConfig: notebook.sync_config && mapProjectSyncConfig(notebook.sync_config),
    portfolioMeta: notebook.portfolio_meta && mapProjectPortfolioMeta(notebook.portfolio_meta),
    latestVersionId: notebook.latest_version_id,
    latestOkVersionId: notebook.latest_ok_version_id,
  }
}

function mapProjectSyncConfig(sync_config: MiPasaProjectSyncConfig): ProjectSyncConfig {
  return {
    id: sync_config.id,
    provider: sync_config.provider,
    repo: sync_config.repo,
    branch: sync_config.branch,
    directory: sync_config.directory,
    syncedAt: sync_config.synced_at,
    syncedVersion: sync_config.synced_version,
  }
}

function mapProjectPortfolioMeta(portfolio_meta: MiPasaProjectPortfolioMeta): ProjectPortfolioMeta {
  return {
    assets: portfolio_meta.assets,
    holdings: portfolio_meta.holdings,
    tradeAsset: portfolio_meta.trade_asset || undefined,
    tradeAssetIcon: portfolio_meta.trade_asset_icon || undefined,
    totalDeposit: portfolio_meta.total_deposit,
    totalWithdrawal: portfolio_meta.total_withdrawal,
    exchangeType: portfolio_meta.exchange,
  }
}

function mapRatePlan({ id, title, details, features, ...ratePlan }: MiPasaRatePlan): RatePlan {
  return {
    id,
    title,
    details,
    features,
    isHighlighted: ratePlan.is_highlighted,
    priceInUnits: ratePlan.price_in_units,
  }
}

function mapSubscription({ id, ...subscription }: MiPasaSubscription): Subscription {
  return {
    id,
    expirationDate: subscription.expiration_date,
    nextBillingDate: subscription.next_billing_date,
    canceledAt: subscription.canceled_at,
    ratePlan: mapRatePlan(subscription.rate_plan),
  }
}

function mapExecution(miPasaRun: MiPasaNotebookRun): Execution {
  return {
    id: miPasaRun.id,
    sequence: miPasaRun.number,
    userId: miPasaRun.started_by.id,
    startedBy: mapUser(miPasaRun.started_by),
    startedAt: miPasaRun.started_at,
    endedAt: miPasaRun.ended_at,
    executionType: miPasaRun.execution_type,
    environment: miPasaRun.environment,
    version: miPasaRun.version,
    file: miPasaRun.file,
    duration: miPasaRun.ended_at ? new Date(miPasaRun.ended_at).getTime() - new Date(miPasaRun.started_at).getTime() : 0,
    executedAt: new Date(miPasaRun.started_at).getTime(),
    haveInsights: false,
    executionStatus: {
      in_progress: 'running',
      ok: 'succeeded',
      error: 'failed',
      aborted: 'failed',
    }[miPasaRun.status] as 'running' | 'succeeded' | 'failed',
  }
}

function mapFile(miPasaFile: MiPasaFileInfo, projectId: string): File {
  const rec = getFileCodingLanguageRecord(miPasaFile.name)

  return {
    id: miPasaFile.id,
    projectId,
    name: miPasaFile.name,
    language: rec?.[0] || 'plaintext',
    description: '',
    data: '',
    isPublic: false,
    publicGroup: 'all',
    parentId: '',
    type: rec?.[1].type || CodingType.script,
    userId: 'never',
    createdAt: new Date(miPasaFile.created_at).getTime(),
    treeType: 'file',
    isConflict: false,
    metadata: miPasaFile.metadata,
  }
}

function mapFileVersion({ id, name, created_at, revisioned_at, payload, file: { id: fileId } }: MiPasaFileVersion): FileVersion {
  return {
    id: `${fileId}/${id}`,
    fileId,
    name,
    threads: [],
    userId: 'never',
    createdAt: new Date(created_at).getTime(),
    revisionedAt: revisioned_at ? new Date(revisioned_at).getTime() : null,
    data: payload,
  }
}

function mapDirectory(directoryName: string, projectId: string, metadata: ProjectDirectoryMetadata): File {
  return {
    id: `${projectId}/${directoryName}`,
    projectId,
    name: directoryName,
    language: 'plaintext',
    description: '',
    data: '',
    isPublic: false,
    publicGroup: 'all',
    parentId: '',
    type: CodingType.script,
    userId: 'never',
    createdAt: new Date().getTime(),
    treeType: 'directory',
    isConflict: false,
    directoryMetadata: metadata,
  }
}

function mapComment({
  author,
  created_at,
  id,
  reaction_by_you,
  likes_count,
  dislikes_count,
  reply_to,
  content,
  replies,
  is_deleted,
}: MiPasaNotebookComment): NotebookComment {
  return {
    id,
    author: mapUser(author),
    createdAt: new Date(created_at).getTime(),
    reactionByYou: reaction_by_you,
    likesCount: likes_count,
    dislikesCount: dislikes_count,
    content,
    replyTo: reply_to,
    replies,
    isDeleted: is_deleted,
  }
}

function mapAnalysisNode(node: NotebookAnalysisNode): NotebookAnalysisNode {
  // Have to use 3 separate cases here so TS can distinguish types from each other
  switch (node.type) {
    case NotebookAnalysisNodeType.notebook:
      return {
        ...node,
        entity: {
          ...node.entity,
          owner: mapUser(node.entity.owner as unknown as MiPasaUser),
        },
      }
    case NotebookAnalysisNodeType.dataset:
      return {
        ...node,
        entity: {
          ...node.entity,
          owner: mapUser(node.entity.owner as unknown as MiPasaUser),
        },
      }
    case NotebookAnalysisNodeType.externalDataset:
    default:
      return node
  }
}

function mapTeamPermissions(miPasaPermissions: Record<string, boolean>): Array<TeamMemberPermission> {
  return Object.getOwnPropertyNames(miPasaPermissions).filter(name => miPasaPermissions[name]) as Array<TeamMemberPermission>
}

function mapTeam(team: MiPasaTeam): Team {
  return {
    id: team.id,
    icon: team.thumbnail?.icon_url || undefined,
    createdAt: new Date(team.created_at).getTime(),
    updatedAt: new Date(team.updated_at).getTime(),
    createdBy: mapUser(team.created_by),
    name: team.name,
    description: team.description || undefined,
    permissions: team.permissions && mapTeamPermissions(team.permissions),
    usersCount: team.users_count || 0,
    projectsCount: team.projects_count || 0,
    isDiscoverable: team.is_discoverable,
    approvableJoin: team.approvable_join,
    contactEmail: team.contact_email || undefined,
    membershipStatus: team.membership_status,
    lastMembers: team.last_members?.map(lm => ({ user: mapUser(lm.user), permissions: lm.permissions })),
  }
}

function mapTeamJoinRequest(joinRequest: MiPasaTeamJoinRequest): TeamJoinRequest {
  return {
    id: joinRequest.id,
    teamId: joinRequest.team_id,
    createdAt: new Date(joinRequest.created_at).getTime(),
    status: joinRequest.status,
    user: mapUser(joinRequest.user),
    reason: joinRequest.reason,
  }
}

function mapProjectUserAccess(userPermission: MiPasaUserPermission): ProjectUserAccess {
  return {
    id: userPermission.id,
    createdAt: userPermission.created_at,
    permissions: userPermission.permissions as NotebookPermission[],
    user: mapUser(userPermission.user),
    author: userPermission.author,
    isOwner: userPermission.is_owner,
  }
}

function mapProjectTeamAccess(teamPermission: MiPasaTeamPermission): ProjectTeamAccess {
  return {
    id: teamPermission.id,
    permissions: teamPermission.permissions as NotebookPermission[],
    team: mapTeam(teamPermission.team),
    createdAt: teamPermission.created_at,
  }
}

function mapTeamMember(teamMember: MiPasaTeamMember): TeamMember {
  return {
    id: teamMember.id,
    permissions: teamMember.permissions,
    user: mapUser(teamMember.user),
    createdAt: teamMember.created_at,
  }
}

function mapMiPasaTeamMemberRequest(teamMemberRequest: TeamMemberRequest): MiPasaTeamMemberRequest {
  return {
    permissions: teamMemberRequest.permissions,
    user_id: teamMemberRequest.userId,
  }
}

function mapProjectAccessRequest({ id, user, created_at, reason, status }: MiPasaProjectAccessRequest): ProjectAccessRequest {
  return {
    id,
    reason,
    status,
    createdAt: new Date(created_at).getTime(),
    user: mapUser(user),
  }
}

function mapProjectInvite(invite: MiPasaProjectInvite): ProjectInvite {
  return {
    id: invite.id,
    email: invite.email,
    permissions: invite.permissions,
    createdAt: invite.created_at,
  }
}

function mapFeedEntry(feedEntry: MiPasaFeedEntry): FeedEntry {
  return {
    id: feedEntry.id,
    action: feedEntry.action,
    target: {
      id: feedEntry.target.id,
      type: feedEntry.target.type,
      name: feedEntry.target.name,
    },
    performedAt: feedEntry.performed_at,
    user: mapUser(feedEntry.user),
    performedBy: feedEntry.performed_by && mapUser(feedEntry.performed_by),
  }
}

function mapTeamInvite(invite: MiPasaTeamInvite): TeamInvite {
  return {
    id: invite.id,
    email: invite.email,
    permissions: invite.permissions,
    createdAt: invite.created_at,
  }
}

function mapInviteLocation(inviteLocation: MiPasaInviteLocation): InviteLocation {
  switch (inviteLocation.type) {
    case 'single_team':
      return { type: InviteLocationType.team, id: inviteLocation.id }

    case 'single_notebook':
      return { type: InviteLocationType.project, id: inviteLocation.id }

    default:
      return { type: InviteLocationType.generic }
  }
}

function mapInvite(invite: MiPasaInvite): Invite {
  return {
    id: invite.id,
    email: invite.email,
    createdAt: invite.created_at,
    to: mapInviteLocation(invite.to),
  }
}

function mapTeamNotebook(teamNotebook: MiPasaTeamNotebook): TeamProject {
  return {
    ...mapNotebook(teamNotebook),
    teamPermissionId: teamNotebook.team_permission_id,
  }
}

function mapUserFollow(userFollow: MiPasaUserFollow): UserFollow {
  return {
    follower: mapUser(userFollow.follower),
    followingByYou: userFollow.following_by_you,
  }
}

function mapGitHubConnection(connection: MiPasaGitHubConnection): GitHubConnection {
  return {
    isConnected: connection.is_connected,
    userName: connection.user_name,
    avatar: connection.avatar,
  }
}

function mapBinanceConnection(connection: MiPasaBinanceConnection): BinanceConnection {
  return {
    isConnected: connection.is_connected,
  }
}

function mapTradingAsset(tradingAsset: MiPasaTradingAsset): TradingAsset {
  return {
    remoteType: tradingAsset.remote_type,
    remoteId: tradingAsset.remote_id,
    symbol: tradingAsset.symbol,
    description: tradingAsset.description,
    imageUrl: tradingAsset.image_url || undefined,
    marketCap: tradingAsset.market_cap,
    price: tradingAsset.price,
    age: tradingAsset.age,
  }
}

function mapTradingAssetData(tradingAsset: MiPasaTradingAssetData): TradingAssetData {
  return {
    marketCaps: tradingAsset.market_caps,
    prices: tradingAsset.prices,
    totalVolumes: tradingAsset.total_volumes,
  }
}

function mapProjectSyncResponse(response: MiPasaProjectSyncResponse): ProjectSyncResponse {
  return {
    syncConfig: mapProjectSyncConfig(response.sync_config),
    localChanges: response.local_changes,
    remoteChanges: response.remote_changes,
  }
}

export function mapNotificationEntry(notificationEntry: MiPasaNotificationEntry): NotificationEntry {
  return {
    id: notificationEntry.id,
    type: notificationEntry.type,
    createdAt: notificationEntry.created_at,
    metadata: notificationEntry.metadata,
    entity: notificationEntry.entity && {
      id: notificationEntry.entity.id,
      type: notificationEntry.entity.type,
      name: notificationEntry.entity.name,
      link: notificationEntry.entity.link,
      slug: notificationEntry.entity.slug,
    },
    seenAt: notificationEntry.seen_at,
    sourceUser: notificationEntry.source_user && mapUser(notificationEntry.source_user),
    subjectUser: notificationEntry.subject_user && mapUser(notificationEntry.subject_user),
    subjectTeam: notificationEntry.subject_team && { id: notificationEntry.subject_team.id, name: notificationEntry.subject_team.name },
  }
}

function mapSelf(miPasaSelf: MiPasaSelf, preferences?: UserPreferences, gitHub?: MiPasaGitHubConnection): Self {
  const roles = miPasaSelf.user.admin ? ['registered', 'domain-admin'] : ['registered']
  const actualGitHub = miPasaSelf.github || gitHub

  return {
    signedIn: true,
    sessionExpired: false,
    token: miPasaSelf.token,
    apiKey: miPasaSelf.api_key,
    user: {
      firstName: miPasaSelf.user.first_name,
      lastName: miPasaSelf.user.last_name,
      balance: 0,
      headline: miPasaSelf.user.headline || '',
      twitterUrl: miPasaSelf.user.social.twitter_url || '',
      linkedInUrl: miPasaSelf.user.social.linkedin_url || '',
      blogUrl: miPasaSelf.user.social.blog_url || '',
      websiteUrl: miPasaSelf.user.social.website_url || '',
      facebookUrl: miPasaSelf.user.social.facebook_url || '',
      company: miPasaSelf.user.company.name || '',
      companyPosition: miPasaSelf.user.company.position || '',
      country: '',
      roles,
      id: miPasaSelf.user.id,
      avatar: miPasaSelf.user.avatar,
      avatarIcon: miPasaSelf.user.avatar_icon,
      credentials: {
        id: miPasaSelf.user.id,
        name: miPasaSelf.user.user_name,
        email: miPasaSelf.user.email,
        phone: '',
        userId: miPasaSelf.user.id,
        confirmedAt: miPasaSelf.user.email_is_confirmed ? new Date().getTime() : undefined,
      },
      githubUsername: actualGitHub?.user_name || undefined,
      githubAvatar: actualGitHub?.avatar || undefined,
      githubOAuthToken: actualGitHub?.is_connected ? 'present' : undefined,
      slackTeamName: miPasaSelf.slack?.team_name || undefined,
      isDiscoverable: miPasaSelf.user.is_discoverable,
      preferences: miPasaSelf.preferences || preferences,
      features: miPasaSelf.features,
      isSuspended: miPasaSelf.user.is_suspended,
      referralCode: miPasaSelf.user.referral_code,
      isIDMManaged: miPasaSelf.user.is_idm_managed,
      isTelegramManaged: miPasaSelf.user.is_telegram_managed,
    },
    session: {
      applicationId: 'mipasa',
      admin: !!miPasaSelf.user.admin,
      adminId: miPasaSelf.masked ? 'masked-admin-id' : undefined,
      confirmed: miPasaSelf.user.email_is_confirmed,
      created: new Date().getTime(),
      id: miPasaSelf.user.id,
      expiration: new Date().getTime() + 86400 * 1000 * 365, // "expires" in a year
      lastActivity: new Date().getTime(),
      miPasaAuthorized: true,
      remote: true,
      roles,
    },
  }
}

interface MiPasaTradingBotChannel {
  id: string
  inserted_at: string
  type: 'private' | 'channel' | 'group'
  title: string
  telegram_chat_id: string
  periodic_updates_enabled: boolean
  periodic_updates_interval: 'daily' | 'weekly'
}

function mapTradingBotChannel(channelRaw: MiPasaTradingBotChannel): TradingBotChannel {
  return {
    id: channelRaw.id,
    insertedAt: channelRaw.inserted_at,
    type: channelRaw.type,
    title: channelRaw.title,
    telegramChatId: channelRaw.telegram_chat_id,
    periodicUpdatesEnabled: channelRaw.periodic_updates_enabled,
    periodicUpdatesInterval: channelRaw.periodic_updates_interval,
  }
}

function createPaginationParams<T extends PaginationOptions>(opts: T) {
  const params = new URLSearchParams()
  opts.page && params.set('page', String(opts.page))
  opts.perPage && params.set('page-size', String(opts.perPage))
  return params
}

export default class MiPasaBackend implements Backend {
  prefix: string

  signal?: AbortSignal

  self?: Self

  constructor(prefix: string) {
    this.prefix = prefix
  }

  isCloneProjectSupported(): boolean {
    return true
  }

  isCollaboratorsSupported(): boolean {
    return true
  }

  isGitHubSupported(): boolean {
    return true
  }

  isGitHubCustomGlobalTokenSupported(): boolean {
    return false
  }

  isSlackSupported(): boolean {
    return true
  }

  isRunsSupported(): boolean {
    return true
  }

  isProjectsSupported(): boolean {
    return true
  }

  isTeamsSupported(): boolean {
    return true
  }

  isCodeCommentingSupported(): boolean {
    return false
  }

  isPricingSupported(): boolean {
    return true
  }

  isWalletSupported(): boolean {
    return true
  }

  isNewsroomSupported(): boolean {
    return true
  }

  isPublicationsSupported(): boolean {
    return true
  }

  isProjectRatingSupported(): boolean {
    return false
  }

  isProjectLicenseSupported(): boolean {
    return true
  }

  isProjectAnalysisSupported(): boolean {
    return true
  }

  isUpdatingMainFileSupported(): boolean {
    return true
  }

  isExecutingMainFileSupported(): boolean {
    return false
  }

  isExecutionWithParametersSupported(): boolean {
    return false
  }

  isScheduleEnabled(): boolean {
    return true
  }

  isEventsSupported(): boolean {
    return true
  }

  isProjectDescriptionSupported(): boolean {
    return true
  }

  isProjectCategoriesSupported(): boolean {
    return true
  }

  isNotificationsSupported(): boolean {
    return true
  }

  isAboutProjectSupported(): boolean {
    return true
  }

  isAdminSupported(): boolean {
    return true
  }

  isInviteSupported(): boolean {
    return true
  }

  isExportProjectSupported(): boolean {
    return true
  }

  isEthereumIDESupported(): boolean {
    return false
  }

  isNewsletterSubscriptionSupported(): boolean {
    return true
  }

  async sendUpvoteProject(): Promise<number> {
    throw new Error('Rating is not supported')
  }

  async sendSubscribeToNewsletter(firstName: string, lastName: string, email: string): Promise<void> {
    await this.sendAddEventEntry({
      type: 'user.subscribed_to_newsletter',
      email,
      firstName,
      lastName,
    })
  }

  async fetchPublications(opts: PublicationPaginationOptions): Promise<PublicationPaginatedResult> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 100}`)
    params.set('page', `${opts?.page || 0}`)
    params.set('sort_by', opts?.sortingField || this.publicationSortingInfo().defaultField.field)
    params.set('sort_direction', opts?.sortingDirection || 'desc')

    if (opts?.aiQuery) {
      params.set('ai_query', JSON.stringify(opts.aiQuery))
    }

    switch (opts?.mode) {
      case ViewMode.shared:
        params.set('access_level', PublicationsAccessLevel.collaborator_shared)
        break
      case ViewMode.private:
        params.set('access_level', PublicationsAccessLevel.link_shared)
        break
      default:
        params.set('access_level', PublicationsAccessLevel.public)
        break
    }

    if (opts.filter) {
      params.set('search', opts.filter)
    }

    if (opts.tags) {
      opts.tags.forEach(tag => params.append('tags[]', tag))
    }

    if (opts.type) {
      params.set('type', opts.type)
    }

    if (opts.owner) {
      params.set('owner', opts.owner)
    }

    const { entries, tags, page_number, page_size, total_pages, total_entries }: MiPasaPublicationsPaginatedResult = await this.fetchJson(
      `${this.prefix}/v1/publications?${params.toString()}`,
    )

    return {
      entries: entries.map(publication => mapPublication(publication, this.prefix)),
      page: page_number,
      perPage: page_size,
      totalPages: total_pages,
      totalEntries: total_entries,
      tags,
    }
  }

  async fetchPublication(fileId: string): Promise<Publication> {
    const publication: MiPasaPublication = await this.fetchJson(`${this.prefix}/v1/publications/files/${fileId}`)

    return mapPublication(publication, this.prefix)
  }

  async fetchPublicationBySlug(slug: string, opts?: PublicationFetchParams): Promise<Publication> {
    const params = new URLSearchParams()

    if (opts?.withPayload) {
      params.set('with_payload', 'true')
    }

    if (opts?.withPermissions) {
      params.set('with_permissions', 'true')
    }

    const publication: MiPasaPublication = await this.fetchJson(`${this.prefix}/v1/publications/by_slug/${slug}?${params.toString()}`)

    return mapPublication(publication, this.prefix)
  }

  async sendUpdatePublication(fileId: string, attrs: PublicationUpdateParams): Promise<Publication> {
    const body: any = {
      title: attrs.title,
      description: attrs.description,
      access_level: attrs.accessLevel,
      cover_image: attrs.coverImage,
      update_version: attrs.updateVersion ? 'true' : 'false',
      tag_ids: attrs.tagIds,
      external_providers: attrs.externalProviders,
      execution_enabled: attrs.executionEnabled,
      execution_copy_project_files: attrs.executionCopyProjectFiles,
    }

    const publication: MiPasaPublication = await this.fetchJson(`${this.prefix}/v1/publications/files/${fileId}`, {
      method: 'PATCH',
      sendJson: true,
      body,
    })

    return mapPublication(publication, this.prefix)
  }

  async sendUpdatePublicationStat(fileId: string, statName: PublicationStatName): Promise<Publication> {
    const publication: MiPasaPublication = await this.fetchJson(`${this.prefix}/v1/publications/files/${fileId}/stats`, {
      method: 'POST',
      sendJson: true,
      body: { stat_name: statName },
    })

    return mapPublication(publication, this.prefix)
  }

  publicationSortingInfo(): BackendSortingInfo {
    const defaultField: BackendSortingOption = {
      field: 'published_at',
      title: 'Publication date',
      internalField: 'publishedAt',
      shortTitle: 'Published',
    }

    const fields = [
      {
        field: 'title',
        title: 'Name',
        internalField: 'name',
      },
      {
        field: 'share_count',
        title: 'Shares',
        internalField: 'shares',
      },
      defaultField,
      {
        field: 'view_count',
        title: 'Views',
        internalField: 'views',
      },
    ]

    return {
      fields,
      defaultField,
    }
  }

  strategySortingInfo(): BackendSortingInfo {
    const defaultField: BackendSortingOption = {
      field: 'performance',
      title: 'Performance',
      internalField: 'performance',
    }

    const fields = [
      defaultField,
      {
        field: 'title',
        title: 'Name',
        internalField: 'name',
      },
      {
        field: 'published_at',
        title: 'Publication date',
        internalField: 'publishedAt',
        shortTitle: 'Published',
      },
      {
        field: 'view_count',
        title: 'Views',
        internalField: 'views',
      },
    ]

    return {
      fields,
      defaultField,
    }
  }

  //
  projectSortingInfo(): BackendSortingInfo {
    const defaultField: BackendSortingOption = {
      field: 'updated_at',
      title: 'Last update',
      internalField: 'updatedAt',
      shortTitle: 'Updated',
    }

    const fields = [
      {
        field: 'clone_count',
        title: 'Clones',
        internalField: 'clone_count',
      },
      {
        field: 'name',
        title: 'Name',
        internalField: 'name',
      },
      {
        field: 'shares',
        title: 'Shares',
        internalField: 'shares',
      },
      {
        field: 'created_at',
        title: 'Creation date',
        internalField: 'createdAt',
        shortTitle: 'Created',
      },
      {
        field: 'last_view',
        title: 'Last viewed',
        internalField: 'lastView',
      },
      defaultField,
      {
        field: 'views',
        title: 'Views',
        internalField: 'views',
      },
    ]

    return {
      fields,
      defaultField,
    }
  }

  async fetchAppStatus(): Promise<AppStatus> {
    const response = await this.fetchJson(`${this.prefix}/v1/status`)

    return {
      versionCommit: response.version_commit,
      versionCommitShort: response.version_commit_short,
      versionTag: response.version_tag,
    }
  }

  async fetchSelf(opts?: FetchSelfOptions): Promise<Self> {
    try {
      const params = new URLSearchParams()

      params.set('with_github', '1')
      params.set('with_preferences', '1')
      params.set('with_features', '1')
      params.set('with_documentation_enabled', '1')

      if (opts?.forceRefresh) {
        params.set('refresh_session', 'true')
      }

      if (opts?.idmToken) {
        params.set('idm_token', opts.idmToken)
      }

      if (opts?.sessionContinue) {
        params.set('session_continue', opts.sessionContinue)
      }

      if (opts?.telegramInitData) {
        params.set('telegram_init_data', opts.telegramInitData)
      }

      if (opts?.telegramOAuthData) {
        params.set('telegram_oauth_data', opts.telegramOAuthData)
      }

      if (opts?.referralCode) {
        params.set('referral_code', opts.referralCode)
      }

      const miPasaSelf: MiPasaSelf = await this.fetchJson(`${this.prefix}/v1/me?${params.toString()}`)

      const self = mapSelf(miPasaSelf)

      this.self = self

      return self
    } catch (e) {
      captureError(e)
      if (e instanceof Error) {
        if (e.message === 'Session timeout') {
          return {
            signedIn: false,
            sessionExpired: true,
          }
        }
      }

      return {
        signedIn: false,
        sessionExpired: false,
      }
    }
  }

  async sendLogOut(): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/log_out`, { method: 'DELETE' })
  }

  async fetchSubscriptionStats(): Promise<SubscriptionStats> {
    const mipasaStats: MiPasaSubscriptionStats = await this.fetchJson(`${this.prefix}/v1/me/subscription_stats`)
    return mapSubscriptionStats(mipasaStats)
  }

  async fetchSignupStats(): Promise<SignupStat[]> {
    const mipasaStats: MiPasaSignupStat[] = await this.fetchJson(`${this.prefix}/v1/me/signup_stats`)
    return mipasaStats.map(mapSignupStat)
  }

  async sendUpdateAboutPage(contentRich: object | null, contentRichDraft: object | null): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/me/about_page`, {
      method: 'PUT',
      body: { content_rich: contentRich, content_rich_draft: contentRichDraft },
      sendJson: true,
    })
  }

  async fetchAboutPage(userId: string): Promise<UserAboutPage> {
    const result: MiPasaUserAboutPage = await this.fetchJson(`${this.prefix}/v1/users/${userId}/about_page`)

    return {
      contentRich: result.content_rich,
      contentRichDraft: result.content_rich_draft,
    }
  }

  async fetchSubscriptionHistory(): Promise<Subscription[]> {
    const miPasaSubscriptions = await this.fetchJson(`${this.prefix}/v1/rate_plan_subscription/history`)

    return miPasaSubscriptions.entries.map((miPasaSubscription: MiPasaSubscription) => mapSubscription(miPasaSubscription))
  }

  async fetchSubscription(): Promise<Subscription | null> {
    const miPasaSubscription = await this.fetchJson(`${this.prefix}/v1/rate_plan_subscription`)

    return miPasaSubscription ? mapSubscription(miPasaSubscription) : null
  }

  async changeSubscription(id: string): Promise<Subscription | null> {
    const miPasaSubscription = await this.fetchJson(`${this.prefix}/v1/rate_plan_subscription`, {
      method: 'POST',
      sendJson: true,
      body: { rate_plan_id: id },
    })

    return miPasaSubscription ? mapSubscription(miPasaSubscription) : null
  }

  async cancelSubscription(): Promise<Subscription | null> {
    const miPasaSubscription = await this.fetchJson(`${this.prefix}/v1/rate_plan_subscription`, {
      method: 'DELETE',
    })

    return miPasaSubscription ? mapSubscription(miPasaSubscription) : null
  }

  isSecretsSupported(): boolean {
    return true
  }

  isNotebookConnectionSupported(): boolean {
    return true
  }

  isCollaborationConnectionSupported(): boolean {
    return false
  }

  isExecutionConnectionSupported(): boolean {
    return false
  }

  isContainerConnectionSupported(): boolean {
    return false
  }

  createCollaborationConnection(): BackendCollaborationConnection {
    throw new Error('Method not implemented.')
  }

  createExecutionConnection(): BackendExecutionConnection {
    throw new Error('Method not implemented.')
  }

  createContainerConnection(): BackendContainerConnection {
    throw new Error('Method not implemented.')
  }

  async fetchSecrets(): Promise<Secret[]> {
    return (await this.fetchJson(`${this.prefix}/v1/secrets`)).entries
  }

  async saveSecret(key: string, secret: string): Promise<Secret> {
    return this.fetchJson(`${this.prefix}/v1/secrets`, {
      method: 'POST',
      sendJson: true,
      body: { key, secret },
    })
  }

  async deleteSecret(id: string): Promise<Secret> {
    return this.fetchJson(`${this.prefix}/v1/secrets/${id}`, {
      method: 'DELETE',
    })
  }

  async fetchTips(): Promise<ResponseCollection<Tip>> {
    const { entries }: ResponseCollection<MiPasaTip> = await this.fetchJson(`${this.prefix}/v1/tips`)
    return { entries: entries.map(mapMiPasaTip) }
  }

  async fetchProjects(opts: ProjectPaginationOptions): Promise<ProjectPaginatedResult> {
    const sortingInfo = this.projectSortingInfo()

    const params = new URLSearchParams()

    if (opts?.section) {
      params.set('section', opts.section)
    }

    let mode: string
    switch (opts?.mode) {
      case ViewMode.private:
        mode = 'my'
        break
      case ViewMode.shared:
        mode = 'shared'
        break
      case ViewMode.public:
        mode = 'community'
        break
      default:
        mode = 'all'
    }

    params.set('mode', mode)
    opts.modeFilters?.forEach(mf => params.append('mode_filter[]', mf))
    params.set('page-size', `${opts?.perPage || 100}`)
    params.set('page', `${opts?.page || 1}`)
    params.set('sort_by', opts?.sortingField || sortingInfo.defaultField.field)
    params.set('sort_direction', opts?.sortingDirection || 'desc')

    if (opts.filter) {
      params.set('search', opts.filter)
    }
    if (opts.ownerId) {
      params.set('owned_by', opts.ownerId)
    }
    if (opts.authorId) {
      params.set('authored_by', opts.authorId)
    }
    if (opts.tags) {
      opts.tags.forEach(tag => params.append('tags[]', tag))
    }

    const { entries, tags, page_number, page_size, total_pages, total_entries }: MiPasaNotebookPaginatedResult = await this.fetchJson(
      `${this.prefix}/v1/notebooks?${params.toString()}`,
    )

    return {
      entries: entries.map(miPasaNb => mapNotebook(miPasaNb, opts?.mode, this.self?.user?.id)),
      tags,
      page: page_number,
      totalPages: total_pages,
      perPage: page_size,
      totalEntries: total_entries,
    }
  }

  async fetchProject(id: string): Promise<Project> {
    const notebook: MiPasaNotebook = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}`)
    return mapNotebook(notebook)
  }

  async fetchProjectFiles(id: string, path?: string): Promise<Array<File>> {
    const params = new URLSearchParams()

    if (path) {
      params.set('dir', path)
    }

    const { entries }: { entries: Array<MiPasaFileEntry | MiPasaDirectoryEntry> } = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${id}/files-dirs?${params.toString()}`,
    )

    return entries.map(entry =>
      entry.type === 'file' ? mapFile(entry.file, id) : mapDirectory(`${path ? `${path}/` : ''}${entry.basename}`, id, entry.metadata),
    )
  }

  private async fetchEntry(id: string, path: string): Promise<File | undefined> {
    const parentPath = getFileParentPath(path)
    const items = await this.fetchProjectFiles(id, parentPath)

    return items.find(item => item.name === path)
  }

  async fetchFile(projectId: string, fileId: string): Promise<File> {
    const miPasaFile: MiPasaFileInfo = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/files/${fileId}`)

    return mapFile(miPasaFile, projectId)
  }

  async fetchFileExists(id: string, path: string): Promise<boolean> {
    return (await this.fetchEntry(id, path)) !== undefined
  }

  async fetchDirectoryExists(id: string, path: string): Promise<boolean> {
    return (await this.fetchEntry(id, path)) !== undefined
  }

  async fetchProjectDirectories(): Promise<Array<File>> {
    return []
  }

  async fetchProjectFileVersions(id: string, fileId: FileId): Promise<Array<FileVersion>> {
    const response: MiPasaPaginatedResult<MiPasaFileVersion> = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${id}/files/${fileId}/versions?page-size=100`,
    )

    return response.entries.map(mapFileVersion)
  }

  async fetchLatestProjectFileVersion(id: string, fileId: FileId, options?: FetchProjectFileOptions): Promise<FileVersion> {
    let data = ''
    if (options?.withData !== false) {
      data = await this.fetchText(await this.fetchLatestProjectFileVersionUrl(id, fileId, options), { cache: 'reload' })
    }

    return {
      id: `${fileId}/${uuid()}`,
      fileId,
      threads: [],
      userId: 'never',
      createdAt: new Date().getTime(),
      data,
    }
  }

  async fetchLatestProjectFileVersionRaw(projectId: string, fileId: FileId, options?: FetchProjectFileOptions): Promise<Blob> {
    const data = await this.fetch(await this.fetchLatestProjectFileVersionUrl(projectId, fileId, options), { cache: 'reload' })

    return data.blob()
  }

  async fetchLatestProjectFileVersionUrl(projectId: string, fileId: FileId, options?: FetchProjectFileOptions): Promise<string> {
    const searchParams = new URLSearchParams()

    if (options?.publicationId) {
      searchParams.set('publication_id', options.publicationId)
    }

    if (options?.versionId) {
      searchParams.set('version_id', options.versionId)
    }

    return `${this.prefix}/v1/notebooks/${projectId}/file_raw_contents/${fileId}?${searchParams.toString()}`
  }

  async fetchProjectMainFileVersions(projectId: string): Promise<PaginatedResult<FileVersion>> {
    const response: MiPasaPaginatedResult<MiPasaFileVersion> = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/versions`)

    return {
      entries: response.entries.map(fileVersion => mapFileVersion(fileVersion)),
      page: response.page_number,
      totalPages: response.total_pages,
      perPage: response.page_size,
      totalEntries: response.total_entries,
    }
  }

  async fetchProjectMainFileVersion(projectId: string, versionId: string): Promise<FileVersion> {
    const fileVersion: MiPasaFileVersion = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/versions/${versionId}`)

    return mapFileVersion(fileVersion)
  }

  async fetchProjectFileVersion(projectId: string, fileId: string, versionId: string, withData?: boolean): Promise<FileVersion> {
    const params = new URLSearchParams()
    if (withData === false) {
      params.set('with_payload', 'false')
    }

    const fileVersion: MiPasaFileVersion = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${projectId}/files/${fileId}/versions/${versionId}?${params.toString()}`,
    )

    return mapFileVersion(fileVersion)
  }

  async sendCreateProject(data: Partial<Project>, content?: Array<unknown>, shouldCreateMainFile = true): Promise<Project> {
    const body: Partial<MiPasaNotebook> & { create_main_file?: boolean } = {
      name: data.name as string,
      language: data.language || null,
      cells: content,
    }

    if (!shouldCreateMainFile) {
      body.create_main_file = false
    }

    const notebook: MiPasaNotebook = await this.fetchJson(`${this.prefix}/v1/notebooks`, { method: 'POST', sendJson: true, body })

    return mapNotebook(notebook)
  }

  async sendUploadProject(params: UploadProjectParams): Promise<Project> {
    const body = { upload_id: params.uploadId, name: params.name, verify_content: params.verifyContent }

    const notebook: MiPasaNotebook = await this.fetchJson(`${this.prefix}/v1/notebooks/upload`, { method: 'POST', sendJson: true, body })

    return mapNotebook(notebook)
  }

  async sendCreateProjectFile(projectId: string, { name, data, language }: Partial<File>, opts: CreateProjectFileOptions): Promise<File> {
    const body: MiPasaFileUpdateInfo = {
      name: name as string,
      content: data as string,
      language: language as string,
      format: opts?.isDataBase64 ? 'base64' : 'text',
      mime_type: autodetectMimeType(name as string, 'text/plain'),
    }

    const file: MiPasaFileWithContent = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/files-contents`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapFile(file, projectId)
  }

  async sendCreateProjectFileVersion(id: string, fileId: FileId, data: string, binary: boolean): Promise<FileVersion> {
    const body: Partial<MiPasaFileUpdateInfo> = {
      content: data,
      format: binary ? 'base64' : 'text',
    }

    await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/files-contents/${fileId}`, { method: 'PATCH', sendJson: true, body })

    return {
      id: `${fileId}/${uuid()}`,
      fileId,
      threads: [],
      userId: 'never',
      createdAt: new Date().getTime(),
      data,
    }
  }

  async sendUpdateProjectFile(projectId: string, data: Partial<File>): Promise<File> {
    const body: Partial<MiPasaFileUpdateInfo> = {
      name: data.name,
      language: data.language,
    }

    const file: MiPasaFileWithContent = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/files-contents/${data.id as FileId}`, {
      method: 'PATCH',
      sendJson: true,
      body,
    })

    return mapFile(file, projectId)
  }

  async sendDeleteProjectFile(projectId: string, fileId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/files/${fileId}`, { method: 'DELETE' })
  }

  async sendDuplicateProjectFile(projectId: string, fileId: string, name?: string): Promise<File> {
    const params = new URLSearchParams()

    name && params.set('name', name)

    const file: MiPasaFileWithContent = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${projectId}/files/${fileId}/duplicate?${params.toString()}`,
      {
        method: 'PUT',
      },
    )

    return mapFile(file, projectId)
  }

  async sendDeleteProject(id: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/notebooks/${id}`, { method: 'DELETE' })
  }

  async sendReplaceProjectDirectory(id: string, oldName: string, newName: string): Promise<Array<File>> {
    const sendOldName = oldName.endsWith('/') ? oldName.substring(0, oldName.length - 1) : oldName
    const sendNewName = newName.endsWith('/') ? newName.substring(0, newName.length - 1) : newName

    const params = new URLSearchParams()

    params.set('from', sendOldName)
    params.set('to', sendNewName)

    const { entries }: { entries: Array<MiPasaFileEntry | MiPasaDirectoryEntry> } = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${id}/files-dirs?${params.toString()}`,
      { method: 'PATCH' },
    )

    return entries.map(entry =>
      entry.type === 'file' ? mapFile(entry.file, id) : mapDirectory(`${sendNewName}/${entry.basename}`, id, entry.metadata),
    )
  }

  private async deleteSubPath(id: string, path: string): Promise<void> {
    const entries = await this.fetchProjectFiles(id, path)

    await Promise.all(
      entries.map(entry => {
        switch (entry.treeType) {
          case 'file':
            return this.sendDeleteProjectFile(id, entry.id)
          case 'directory':
            return this.deleteSubPath(id, entry.name)
          default:
            return assertUnreachable(entry.treeType)
        }
      }),
    )
  }

  async sendDeleteProjectDirectory(id: string, path: string): Promise<void> {
    return this.deleteSubPath(id, path)
  }

  async sendReplaceProject(id: string, data: Project): Promise<Project> {
    const body: Partial<MiPasaNotebook> = {
      name: data.name,
      description: data.description,
      license: data.license,
      published_at: data.publishedAt ? new Date(data.publishedAt).toISOString() : undefined,
    }

    const notebook: MiPasaNotebookBase = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}`, { method: 'PATCH', sendJson: true, body })

    return { ...data, ...mapNotebookBase(notebook) }
  }

  async sendCloneProject(projectId: string): Promise<Project> {
    return this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/clone`, { method: 'POST' })
  }

  async fetchProjectUserPermissions(projectId: string, opts: FetchPermissionsPaginationOptions = {}): Promise<PaginatedResult<ProjectUserAccess>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts.perPage ?? 100}`)

    if (opts.filter) {
      params.set('search', opts.filter)
    }

    const { total_entries, page_size, total_pages, page_number, entries }: MiPasaPaginatedResult<MiPasaUserPermission> = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${projectId}/user_permissions?${params.toString()}`,
    )

    return {
      totalEntries: total_entries,
      perPage: page_size,
      totalPages: total_pages,
      page: page_number,
      entries: entries.map(mapProjectUserAccess),
    }
  }

  async fetchProjectTeamPermissions(projectId: string, opts: FetchPermissionsPaginationOptions = {}): Promise<PaginatedResult<ProjectTeamAccess>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts.perPage ?? 100}`)

    if (opts.filter) {
      params.set('search', opts.filter)
    }

    const { total_entries, page_size, total_pages, page_number, entries }: MiPasaPaginatedResult<MiPasaTeamPermission> = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${projectId}/team_permissions?${params.toString()}`,
    )

    return {
      totalEntries: total_entries,
      perPage: page_size,
      totalPages: total_pages,
      page: page_number,
      entries: entries.map(mapProjectTeamAccess),
    }
  }

  async fetchUser(username: string): Promise<UserInfo> {
    const user: MiPasaUser = await this.fetchJson(`${this.prefix}/v1/users/by_user_name/${encodeURIComponent(username)}`)

    return mapUser(user)
  }

  async fetchUsers(search: string): Promise<UserInfo[]> {
    const params = new URLSearchParams()
    params.set('search', search)

    const users = await this.fetchJson(`${this.prefix}/v1/users?${params.toString()}`)
    return (users?.entries || []).map(mapUser)
  }

  async grantUserPermissions(projectId: string, userId: string, permissions: NotebookPermission[]): Promise<ProjectUserAccess> {
    const body = { user_id: userId, permissions }

    const response: MiPasaUserPermission = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/user_permissions`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapProjectUserAccess(response)
  }

  async revokeUserPermissions(projectId: string, userId: string): Promise<ProjectUserAccess> {
    const response: MiPasaUserPermission = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/user_permissions/${userId}`, {
      method: 'DELETE',
    })

    return mapProjectUserAccess(response)
  }

  async grantTeamPermissions(projectId: string, teamId: string, permissions: NotebookPermission[]): Promise<ProjectTeamAccess> {
    const body = { team_id: teamId, permissions }

    const response: MiPasaTeamPermission = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/team_permissions`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapProjectTeamAccess(response)
  }

  async revokeTeamPermissions(projectId: string, teamId: string): Promise<ProjectTeamAccess> {
    const response: MiPasaTeamPermission = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/team_permissions/${teamId}`, {
      method: 'DELETE',
    })

    return mapProjectTeamAccess(response)
  }

  canExecuteFile(file: File): boolean {
    return !!file.name.match(/\.(ipynb|mpsw|pasa|pass|strat|mpsf)$/i)
  }

  async fetchExecutions(id: string): Promise<Array<ExecutionSummary>> {
    const { entries }: { entries: Array<MiPasaNotebookRun> } = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/runs`)

    return entries.map(mapExecution)
  }

  async fetchPaginatedExecutions(id: string, _fileId: string, opts?: FetchExecutionsOptions): Promise<PaginatedResult<Execution>> {
    const params = new URLSearchParams()

    params.set('page', `${opts?.page || 1}`)
    params.set('page-size', `${opts?.perPage || 10}`)

    if (opts?.mode) {
      params.set('mode', opts.mode)
    }

    if (opts?.search) {
      params.set('search', opts.search)
    }

    const {
      entries,
      page_number: pageNumber,
      page_size: pageSize,
      total_pages: totalPages,
    }: MiPasaPaginatedResult<MiPasaNotebookRun> = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/runs?${params.toString()}`)

    return {
      entries: entries.map(mapExecution),
      page: pageNumber,
      perPage: pageSize,
      totalPages,
    }
  }

  async fetchExecution(id: string, _fileId: string, executionId: string): Promise<Execution> {
    const execution: MiPasaNotebookRun = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/runs/${executionId}`)

    return mapExecution(execution)
  }

  async sendStartExecution(id: string): Promise<Execution> {
    const execution: MiPasaNotebookRun = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/runs`, { method: 'POST' })

    return mapExecution(execution)
  }

  async sendStopExecution(id: string, _fileId: string, executionId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/runs/${executionId}`, { method: 'DELETE' })
  }

  async sendUpdateProjectPublicity(projectId: string, isPublic: boolean): Promise<Project> {
    let notebook: MiPasaNotebookBase
    const body = { public: isPublic }

    try {
      notebook = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}`, { method: 'PATCH', sendJson: true, body })
    } catch (e) {
      if (e instanceof Error) {
        throw e
      }
      throw new Error('Error publishing notebook')
    }

    return mapNotebookBase(notebook)
  }

  async sendUpdateProjectOwner(projectId: string, userId: string): Promise<Project> {
    const body = { user_id: userId }

    const {
      authors = [],
      permissions = {},
      ...notebookBase
    }: MiPasaNotebookBase & Pick<MiPasaNotebook, 'authors' | 'permissions'> = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/owner`, {
      method: 'PATCH',
      sendJson: true,
      body,
    })

    return { ...mapNotebookBase(notebookBase), permissions: mapPermissions(permissions), authors: authors.map(mapUser) }
  }

  async updateProjectUserPermissions(projectId: string, permissionId: string, permissions: string[]): Promise<ProjectUserAccess> {
    const body = { permissions }

    const response: MiPasaUserPermission = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/user_permissions/${permissionId}`, {
      method: 'PATCH',
      sendJson: true,
      body,
    })

    return mapProjectUserAccess(response)
  }

  async updateProjectTeamPermissions(projectId: string, permissionId: string, permissions: string[]): Promise<ProjectTeamAccess> {
    const body = { permissions }

    const response: MiPasaTeamPermission = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/team_permissions/${permissionId}`, {
      method: 'PATCH',
      sendJson: true,
      body,
    })

    return mapProjectTeamAccess(response)
  }

  async toggleProjectPermissionAuthor(projectId: string, permissionId: string, author: boolean): Promise<void> {
    const method = author ? 'POST' : 'DELETE'

    await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/user_permissions/${permissionId}/author`, { method })
  }

  async fetchTags(): Promise<Tag[]> {
    const { entries: items } = await this.fetchJson(`${this.prefix}/v1/tags`)

    return items
  }

  async sendUpdateProjectTags(projectId: string, tags: string[]): Promise<Project> {
    const notebook: MiPasaNotebookBase = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/tags`, {
      method: 'POST',
      sendJson: true,
      body: {
        tag_ids: tags,
      },
    })

    return mapNotebookBase(notebook)
  }

  async sendUpdateProjectThumbnail(projectId: string, data: FormData): Promise<Project> {
    const notebook: MiPasaNotebookBase = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/thumbnail`, {
      method: 'POST',
      body: data,
    })

    return mapNotebookBase(notebook)
  }

  async fetchNotebookComments(projectId: string, fileId: string): Promise<PaginatedResult<NotebookComment>> {
    const response: MiPasaPaginatedResult<MiPasaNotebookComment> = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${projectId}/comments?page-size=100&project_file_id=${fileId}`,
    )

    return {
      entries: (response.entries || []).map(c => mapComment(c)),
      page: response.page_number,
      totalPages: response.total_pages,
      perPage: response.page_size,
      totalEntries: response.total_entries,
    }
  }

  async addNotebookComment(projectId: string, fileId: string, replyTo: string, content: NotebookCommentContent): Promise<NotebookComment | null> {
    const comment: MiPasaNotebookComment = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/comments?project_file_id=${fileId}`, {
      method: 'POST',
      sendJson: true,
      body: {
        reply_to: replyTo,
        content,
      },
    })

    return mapComment(comment)
  }

  async deleteNotebookComment(id: string, commentId: string): Promise<NotebookComment | null> {
    const comment: MiPasaNotebookComment = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/comments/${commentId}`, {
      method: 'DELETE',
    })

    return mapComment(comment)
  }

  async sendAddNotebookCommentReaction(projectId: string, commentId: string, reaction: NotebookCommentReaction): Promise<NotebookComment | null> {
    const comment: MiPasaNotebookComment = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/comments/${commentId}/react`, {
      method: 'POST',
      sendJson: true,
      body: { reaction },
    })

    return mapComment(comment)
  }

  async sendRemoveNotebookCommentReaction(projectId: string, commentId: string): Promise<NotebookComment | null> {
    const comment: MiPasaNotebookComment = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/comments/${commentId}/react`, {
      method: 'DELETE',
    })

    return mapComment(comment)
  }

  async editNotebookComment(id: string, commentId: string, content: NotebookCommentContent): Promise<NotebookComment | null> {
    const comment: MiPasaNotebookComment = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/comments/${commentId}`, {
      method: 'PATCH',
      sendJson: true,
      body: {
        content,
      },
    })

    return mapComment(comment)
  }

  async reportNotebookComment(id: string, commentId: string): Promise<NotebookComment | null> {
    const comment: MiPasaNotebookComment = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/comments/${commentId}/report`, {
      method: 'POST',
      sendJson: true,
      body: {
        reason: 'User report',
      },
    })

    return mapComment(comment)
  }

  async getNotificationsCount(): Promise<number | undefined> {
    // TODO to be implemented
    return 0
  }

  async fetchBalance(): Promise<Balance> {
    const miPasaBalance: MiPasaBalance = await this.fetchJson(`${this.prefix}/v1/balance`)

    return {
      userId: miPasaBalance.user_id,
      balance: miPasaBalance.balance,
      unboundedBalance: 0,
    }
  }

  async fetchProfile(): Promise<Profile> {
    const self = await this.fetchSelf()

    if (!self.signedIn || !self.user || !self.user.credentials) {
      throw new Error('Unauthorized')
    }

    return {
      ...self.user,
      apiKey: '',
      createdAt: new Date().getTime(),
      email: self.user.credentials.email || '',
      emailConfirmations: [],
      emailNotConfirmedUnbounded: false,
      githubPersonalAccessTokenPresent: false,
      githubPersonalAccessTokenExpiration: 0,
      username: self.user.credentials.name,
    }
  }

  fetchSettings(): Promise<Record<string, any>> {
    return this.fetchJson(`${this.prefix}/v1/preferences`)
  }

  sendUpdateSettings(settings: Record<string, any>): Promise<void> {
    return this.fetchJson(`${this.prefix}/v1/preferences`, { method: 'PATCH', sendJson: true, body: settings })
  }

  async fetchNotebookAnalysisProvenance(id: string, direction: NotebookAnalysisDirection): Promise<NotebookAnalysis> {
    const { nodes, ...analysis }: NotebookAnalysis = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${id}/analysis/provenance?direction=${direction}`,
    )

    return {
      ...analysis,
      nodes: nodes.map(mapAnalysisNode),
    }
  }

  async fetchNotebookAnalysisInheritance(id: string): Promise<NotebookAnalysis> {
    const { nodes, ...analysis }: NotebookAnalysis = await this.fetchJson(`${this.prefix}/v1/notebooks/${id}/analysis/inheritance`)

    return {
      ...analysis,
      nodes: nodes.map(mapAnalysisNode),
    }
  }

  createNotebookConnection(projectId: string, fileId: string, params: BackendNotebookConnectionParameters): BackendNotebookConnection {
    return new MiPasaNotebookConnection(this, { projectId, fileId }, params)
  }

  createNotebookEmbedConnection(embedId: string, params: BackendNotebookConnectionParameters): BackendNotebookConnection {
    return new MiPasaNotebookConnection(this, { embedId }, params)
  }

  createCellEmbedConnection(
    projectId: string,
    fileId: string,
    cellId: string,
    outputIndex?: string,
    mode?: BackendCellEmbedConnectionMode,
  ): BackendCellEmbedConnection {
    return new MiPasaCellEmbedConnection(this, projectId, fileId, cellId, outputIndex, mode)
  }

  async fetchScheduledNotebooks(opts: ScheduledNotebookListingOptions): Promise<PaginatedResult<ScheduledNotebook<UserInfo>>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 10}`)
    params.set('page', `${opts?.page || 0}`)
    if (opts.time) {
      params.set('time', opts.time)
    }
    if (opts.activation) {
      params.set('activation', opts.activation)
    }
    if (opts.recurrence) {
      params.set('recurrence', opts.recurrence)
    }
    if (opts.sortingField) {
      params.set('sort_by', opts.sortingField)
    }
    if (opts.sortingDirection) {
      params.set('sort_direction', opts.sortingDirection)
    }
    if (opts.adminMode) {
      params.set('admin_mode', 'true')
    }
    if (opts.ownerSearch) {
      params.set('owner_search', opts.ownerSearch)
    }

    const response: {
      entries: Array<ScheduledNotebook<MiPasaUser>>
      total_entries: number
      total_pages: number
      page_size: number
      page_number: number
    } = await this.fetchJson(`${this.prefix}/v1/schedules?${params.toString()}`)

    const mappedResponse = {
      ...response,
      entries: response.entries.map(entry => ({ ...entry, project: { ...entry.project, owner: mapUser(entry.project.owner) } })),
    }

    return {
      entries: mappedResponse.entries,
      totalPages: response.total_pages,
      page: response.page_number,
      perPage: response.page_size,
    }
  }

  async fetchUserServers(opts: PaginationOptions): Promise<PaginatedResult<UserServer>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 10}`)
    params.set('page', `${opts?.page || 0}`)

    const response: {
      entries: Array<UserServer>
      total_entries: number
      total_pages: number
      page_size: number
      page_number: number
    } = await this.fetchJson(`${this.prefix}/v1/my_servers?${params.toString()}`)

    return {
      entries: response.entries,
      totalPages: response.total_pages,
      page: response.page_number,
      perPage: response.page_size,
    }
  }

  async sendStopUserServer(serverId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/my_servers/${serverId}`, { method: 'DELETE' })
  }

  fetchNotebookSchedule(projectId: string, fileId: string): Promise<ScheduledExecution> {
    return this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/files/${fileId}/schedule`)
  }

  sendNotebookSchedule(projectId: string, fileId: string, data: ScheduledExecutionPatch): Promise<ScheduledExecution> {
    return this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/files/${fileId}/schedule`, {
      method: 'PATCH',
      sendJson: true,
      body: { schedule: data },
    })
  }

  fileUrlByName(projectId: string, name: string): string {
    return `${this.prefix}/v1/notebooks/${projectId}/file_raw_contents/by_file_name/${name}`
  }

  isFileUrlByNameSupported(): boolean {
    return true
  }

  async sendAddEventEntry(eventData: EventData): Promise<EventEntry> {
    const body: Record<string, unknown> = { type: eventData.type }

    if (eventData.type === 'documentation.viewed' || eventData.type === 'publication.viewed' || eventData.type === 'notebook.viewed') {
      body.resource_id = eventData.resourceId
      body.client_data = eventData.clientData
    }

    if (eventData.type === 'documentation.viewed') {
      body.section_id = eventData.sectionId
    }

    if (eventData.type === 'publication.viewed') {
      body.referral_code = eventData.referralCode
    }

    if (eventData.type === 'user.subscribed_to_newsletter') {
      body.email = eventData.email
      body.first_name = eventData.firstName
      body.last_name = eventData.lastName
    }

    const miPasaEventEntry: MiPasaEventEntry = await this.fetchJson(`${this.prefix}/v1/events`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return {
      type: miPasaEventEntry.type,
      resourceId: miPasaEventEntry.resource_id,
    }
  }

  mapNewsroomEntry(entry: NewsroomEntry): NewsroomEntry {
    const newEntry = { ...entry }

    if (newEntry.image?.startsWith('/') && !newEntry.image?.startsWith('//')) {
      newEntry.image = `${this.prefix}${newEntry.image}`
    }

    return newEntry
  }

  async fetchDocumentation(): Promise<Array<DocumentationSection>> {
    const entries: Array<MiPasaDocumentationSection> = await this.fetchJson(`${this.prefix}/v1/documentation`)

    return entries.map(x => mapDocumentationSection(x, this.prefix))
  }

  async sendUpdateDocumentationSection(id: string, update: Partial<DocumentationSectionUpdate>): Promise<DocumentationSection> {
    const response: MiPasaDocumentationSection = await this.fetchJson(`${this.prefix}/v1/documentation/${id}`, {
      method: 'PATCH',
      body: update,
      sendJson: true,
    })

    return mapDocumentationSection(response, this.prefix)
  }

  async fetchNewsroom(): Promise<Array<NewsroomEntry>> {
    const entries: Array<NewsroomEntry> = await this.fetchJson(`${this.prefix}/v1/newsroom`)

    return entries.map(this.mapNewsroomEntry.bind(this))
  }

  async fetchNewsroomEntry(id: string): Promise<NewsroomEntry> {
    const entry = await this.fetchJson(`${this.prefix}/v1/newsroom/${id}`)

    return this.mapNewsroomEntry(entry)
  }

  async sendCreateNewsroomEntry(entry: NewsroomEntryUpdate): Promise<NewsroomEntry> {
    const sendEntry: Partial<NewsroomEntry> = {
      name: entry.name,
      url: entry.url,
      image: null,
      published_at: entry.published_at,
    }

    if (entry.image !== undefined) {
      if (entry.image !== null) {
        const uploadResponse: MiPasaUpload = await this.fetchJson(`${this.prefix}/v1/newsroom/upload-image`, { method: 'POST' })

        await this.fetchText(uploadResponse.url, {
          method: uploadResponse.method,
          sendJson: false,
          body: new Blob([entry.image]),
        })
        sendEntry.image = uploadResponse.id
      } else {
        sendEntry.image = null
      }
    }

    const createdEntry = await this.fetchJson(`${this.prefix}/v1/newsroom`, { method: 'POST', body: sendEntry, sendJson: true })

    return this.mapNewsroomEntry(createdEntry)
  }

  async sendUpdateNewsroomEntry(id: string, update: Partial<NewsroomEntryUpdate>): Promise<NewsroomEntry> {
    const sendUpdate: Partial<NewsroomEntry> = {
      name: update.name,
      url: update.url,
      image: undefined,
      published_at: update.published_at,
    }

    if (update.image !== undefined) {
      if (update.image !== null) {
        const uploadResponse: MiPasaUpload = await this.fetchJson(`${this.prefix}/v1/newsroom/upload-image`, { method: 'POST' })

        await this.fetchText(uploadResponse.url, {
          method: uploadResponse.method,
          sendJson: false,
          body: new Blob([update.image]),
        })
        sendUpdate.image = uploadResponse.id
      } else {
        sendUpdate.image = null
      }
    }

    const entry = await this.fetchJson(`${this.prefix}/v1/newsroom/${id}`, { method: 'PATCH', body: sendUpdate, sendJson: true })

    return this.mapNewsroomEntry(entry)
  }

  async sendDeleteNewsroomEntry(id: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/newsroom/${id}`, { method: 'DELETE' })
  }

  createNotificationsConnection(userId: string): BackendNotificationsConnection {
    return new MiPasaNotificationsConnection(this, userId)
  }

  isNotificationsWSSupported(): boolean {
    return true
  }

  createProjectImportConnection(): BackendProjectImportConnection {
    return new MiPasaProjectImportConnection(this)
  }

  createCollaboratorLobbyConnection(projectId: string): BackendCollaboratorLobbyConnection {
    return new MiPasaCollaboratorLobbyConnection(this, projectId)
  }

  createFileChannelConnection(projectId: string, fileId: string): BackendFileChannelConnection {
    return new MiPasaFileChannelConnection(this, projectId, fileId)
  }

  createTrackingConnection(): BackendTrackingConnection {
    return new MiPasaTrackingConnection(this)
  }

  createTradingTransactionsConnection(projectId?: string): BackendTradingTransactionsConnection {
    return new MiPasaTradingTransactionsConnection(this, projectId)
  }

  async fetchToken(): Promise<UserToken> {
    try {
      const miPasaSelf: MiPasaSelf = await this.fetchJson(`${this.prefix}/v1/me`)

      return { token: miPasaSelf.token }
    } catch (e) {
      captureError(e)
      return { token: undefined }
    }
  }

  async createOAuthState(reason: string): Promise<string> {
    const stateValue: { value: string } = await this.fetchJson(`${this.prefix}/v1/me/oauthstate`, {
      method: 'POST',
      body: { reason },
      sendJson: true,
    })
    return stateValue.value
  }

  async verifySlackCode(code: string, state: string, reason: string, redirectUri: string): Promise<void> {
    await this.fetch(`${this.prefix}/v1/slack/verify`, { method: 'POST', body: { code, state, reason, redirect_uri: redirectUri }, sendJson: true })
  }

  async disconnectSlack(): Promise<void> {
    await this.fetch(`${this.prefix}/v1/slack/disconnect`, { method: 'DELETE' })
  }

  async fetchSlackChannels(params?: FetchSlackChannelsParams): Promise<CursorPaginatedResponse<BackendSlackChannel>> {
    const urlParams = new URLSearchParams()

    params?.cursor && urlParams.set('cursor', params.cursor)
    params?.limit && urlParams.set('limit', String(params.limit))
    params?.types?.forEach(t => urlParams.append('types[]', t))

    const response: MiPasaBackendSlackChannelsResponse = await this.fetchJson(`${this.prefix}/v1/slack/channels?${urlParams.toString()}`)

    return {
      entries: response.entries.map(mapSlackChannel),
      nextCursor: response.next_cursor,
    }
  }

  async fetchProjectAccessRequests(projectId: string, opts: PaginationOptions): Promise<PaginatedResult<ProjectAccessRequest>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 50}`)

    const { total_entries, page_size, total_pages, page_number, entries }: MiPasaPaginatedResult<MiPasaProjectAccessRequest> = await this.fetchJson(
      `${this.prefix}/v1/projects/${projectId}/access_requests?${params.toString()}`,
    )

    return {
      totalEntries: total_entries,
      perPage: page_size,
      totalPages: total_pages,
      page: page_number,
      entries: entries.map(mapProjectAccessRequest),
    }
  }

  async fetchProjectAccessRequest(projectId: string, id: string): Promise<ProjectAccessRequest> {
    const accessRequest: MiPasaProjectAccessRequest = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/access_requests/${id}`)

    return mapProjectAccessRequest(accessRequest)
  }

  async fetchMyProjectAccessRequest(projectId: string): Promise<ProjectAccessRequest> {
    const accessRequest: MiPasaProjectAccessRequest = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/access_requests/my`)

    return mapProjectAccessRequest(accessRequest)
  }

  async sendCreateProjectAccessRequest(projectId: string, reason: string): Promise<ProjectAccessRequest> {
    const accessRequest: MiPasaProjectAccessRequest = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/access_requests`, {
      method: 'POST',
      body: { reason },
      sendJson: true,
    })

    return mapProjectAccessRequest(accessRequest)
  }

  async sendDeleteProjectAccessRequest(projectId: string, id: string): Promise<ProjectAccessRequest> {
    const accessRequest: MiPasaProjectAccessRequest = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/access_requests/${id}`, {
      method: 'DELETE',
    })

    return mapProjectAccessRequest(accessRequest)
  }

  async sendApproveProjectAccessRequest(projectId: string, id: string): Promise<ProjectAccessRequest> {
    const accessRequest: MiPasaProjectAccessRequest = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/access_requests/${id}/approve`, {
      method: 'POST',
    })

    return mapProjectAccessRequest(accessRequest)
  }

  async sendRejectProjectAccessRequest(projectId: string, id: string): Promise<ProjectAccessRequest> {
    const accessRequest: MiPasaProjectAccessRequest = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/access_requests/${id}/reject`, {
      method: 'POST',
    })

    return mapProjectAccessRequest(accessRequest)
  }

  async fetchTeams(opts: FetchTeamsPaginationOptions): Promise<PaginatedResult<Team>> {
    const params = new URLSearchParams()

    params.set('mode', opts?.mode || 'all')
    params.set('page-size', `${opts?.perPage || 100}`)
    params.set('page', `${opts?.page || 0}`)
    params.set('sort_by', opts?.sortingField || this.teamSortingInfo().defaultField.field)
    params.set('sort_direction', opts?.sortingDirection || 'desc')

    if (opts?.membersCount) {
      params.set('members_count', `${opts.membersCount}`)
    }

    if (opts.memberId) {
      params.set('member_id', opts.memberId)
    }

    if (opts.filter) {
      params.set('search', opts.filter)
    }

    const response: {
      entries: Array<MiPasaTeam>
      page_number: number
      page_size: number
      total_pages: number
      total_entries: number
    } = await this.fetchJson(`${this.prefix}/v1/teams?${params.toString()}`)

    return {
      entries: response.entries.map(mapTeam),
      page: response.page_number,
      perPage: response.page_size,
      totalPages: response.total_pages,
      totalEntries: response.total_entries,
    }
  }

  async fetchTeam(teamId: string): Promise<Team> {
    const response: MiPasaTeam = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}`)

    return mapTeam(response)
  }

  async sendCreateTeam(
    { name, description, contactEmail, isDiscoverable, approvableJoin, members, invites }: TeamCreateDraft,
    isCheckOnly: boolean,
  ): Promise<Team> {
    const url = `${this.prefix}/v1/teams${isCheckOnly ? '?dry_run=true' : ''}`

    const miPasaMembers = members?.map(m => mapMiPasaTeamMemberRequest(m))
    const body: MiPasaTeamDraft = {
      name,
      description,
      contact_email: contactEmail,
      is_discoverable: isDiscoverable,
      approvable_join: approvableJoin,
      members: miPasaMembers,
      invites,
    }

    const team: MiPasaTeam = await this.fetchJson(url, { method: 'POST', sendJson: true, body })

    return mapTeam(team)
  }

  async sendUpdateTeam(teamId: string, { name, description, contactEmail, isDiscoverable, approvableJoin }: Partial<TeamDraft>): Promise<Team> {
    const body: Partial<MiPasaTeamDraft> = {
      name,
      description,
      contact_email: contactEmail,
      is_discoverable: isDiscoverable,
      approvable_join: approvableJoin,
    }

    const team: MiPasaTeam = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}`, { method: 'PATCH', sendJson: true, body })

    return mapTeam(team)
  }

  async sendUpdateTeamThumbnail(teamId: string, data: FormData): Promise<Team> {
    const team: MiPasaTeam = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/thumbnail`, {
      method: 'POST',
      body: data,
    })

    return mapTeam(team)
  }

  async sendDeleteTeam(teamId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/teams/${teamId}`, { method: 'DELETE' })
  }

  async fetchTeamMembers(teamId: string, opts: FetchMembersPaginationOptions): Promise<PaginatedResult<TeamMember>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 100}`)

    if (opts.filter) {
      params.set('search', opts.filter)
    }

    const { total_entries, page_size, total_pages, page_number, entries }: MiPasaPaginatedResult<MiPasaTeamMember> = await this.fetchJson(
      `${this.prefix}/v1/teams/${teamId}/members?${params.toString()}`,
    )

    return {
      totalEntries: total_entries,
      perPage: page_size,
      totalPages: total_pages,
      page: page_number,
      entries: entries.map(mapTeamMember),
    }
  }

  async sendCreateTeamMember(teamId: string, userId: string, permissions: Array<TeamMemberPermission>): Promise<TeamMember> {
    const body = {
      user_id: userId,
      permissions,
    }

    const response: MiPasaTeamMember = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/members`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapTeamMember(response)
  }

  async sendUpdateTeamMember(teamId: string, memberId: string, permissions: Array<TeamMemberPermission>): Promise<TeamMember> {
    const body = { permissions }

    const response: MiPasaTeamMember = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/members/${memberId}`, {
      method: 'PATCH',
      sendJson: true,
      body,
    })

    return mapTeamMember(response)
  }

  async sendRemoveTeamMember(teamId: string, memberId: string): Promise<TeamMember> {
    const response: MiPasaTeamMember = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/members/${memberId}`, { method: 'DELETE' })

    return mapTeamMember(response)
  }

  async fetchTeamJoinRequests(teamId: string, opts: PaginationOptions): Promise<PaginatedResult<TeamJoinRequest>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 100}`)

    const { total_entries, page_size, total_pages, page_number, entries }: MiPasaPaginatedResult<MiPasaTeamJoinRequest> = await this.fetchJson(
      `${this.prefix}/v1/teams/${teamId}/join_requests?${params.toString()}`,
    )

    return {
      totalEntries: total_entries,
      perPage: page_size,
      totalPages: total_pages,
      page: page_number,
      entries: entries.map(mapTeamJoinRequest),
    }
  }

  async sendRespondTeamJoinRequest(teamId: string, joinRequestId: string, isApproved: boolean): Promise<TeamJoinRequest> {
    const respond = isApproved ? 'approve' : 'reject'
    const response: MiPasaTeamJoinRequest = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/join_requests/${joinRequestId}/${respond}`, {
      method: 'POST',
    })

    return mapTeamJoinRequest(response)
  }

  async sendCreateTeamJoinRequest(teamId: string, reason?: string): Promise<TeamJoinRequest> {
    const body = {
      reason,
    }

    const response: MiPasaTeamJoinRequest = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/join_requests`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapTeamJoinRequest(response)
  }

  async sendCancelTeamJoinRequest(teamId: string, joinRequestId: string): Promise<void> {
    return this.fetchJson(`${this.prefix}/v1/teams/${teamId}/join_requests/${joinRequestId}`, {
      method: 'DELETE',
    })
  }

  async fetchActiveTeamJoinRequest(teamId: string): Promise<TeamJoinRequest> {
    return this.fetchJson(`${this.prefix}/v1/teams/${teamId}/join_requests/active`)
  }

  async sendLeaveTeam(teamId: string): Promise<Team> {
    const response: MiPasaTeam = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/leave`, {
      method: 'POST',
    })

    return mapTeam(response)
  }

  async fetchTeamProjects(teamId: string, opts: ProjectPaginationOptions): Promise<PaginatedResult<TeamProject>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 100}`)
    params.set('page', `${opts?.page || 0}`)
    params.set('sort_by', opts?.sortingField || 'updated_at')
    params.set('sort_direction', opts?.sortingDirection || 'desc')

    if (opts.filter) {
      params.set('search', opts.filter)
    }

    const response: MiPasaPaginatedResult<MiPasaTeamNotebook> = await this.fetchJson(
      `${this.prefix}/v1/teams/${teamId}/notebooks?${params.toString()}`,
    )

    return {
      entries: response.entries.map(notebook => mapTeamNotebook(notebook)),
      page: response.page_number,
      totalPages: response.total_pages,
      perPage: response.page_size,
      totalEntries: response.total_entries,
    }
  }

  async fetchTeamActivity(teamId: string, opts: PaginationOptions): Promise<PaginatedResult<FeedEntry>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 20}`)
    params.set('page', `${opts?.page || 0}`)

    const response: MiPasaPaginatedResult<MiPasaFeedEntry> = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/feed?${params.toString()}`)

    return {
      entries: response.entries.map(mapFeedEntry),
      page: response.page_number,
      perPage: response.page_size,
      totalPages: response.total_pages,
      totalEntries: response.total_entries,
    }
  }

  teamSortingInfo(): BackendSortingInfo {
    const defaultField: BackendSortingOption = {
      field: 'created_at',
      title: 'Creation date',
      internalField: 'createdAt',
    }

    const fields = [
      defaultField,
      {
        field: 'name',
        title: 'Name',
        internalField: 'name',
      },
      {
        field: 'updated_at',
        title: 'Last update',
        internalField: 'updatedAt',
      },
      {
        field: 'users_count',
        title: 'Users',
        internalField: 'usersCount',
      },
      {
        field: 'projects_count',
        title: 'Projects',
        internalField: 'projectsCount',
      },
    ]

    return {
      fields,
      defaultField,
    }
  }

  teamDiscoveryInfo(isAdmin: boolean): BackendSelectInfo<TeamDiscovery> {
    const defaultOption = {
      key: TeamDiscovery.private,
      value: 'Private',
      caption: 'Only invited users can see and access the team',
    }

    const adminOptions = [
      {
        key: TeamDiscovery.public,
        value: 'Public',
        caption: `Members of ${CURRENT_PLATFORM.name} can find and request to join with admin approval`,
      },
      {
        key: TeamDiscovery.open,
        value: 'Open',
        caption: `Members of ${CURRENT_PLATFORM.name} can find and join`,
      },
    ]

    const options = [defaultOption]

    return {
      options: isAdmin ? [...options, ...adminOptions] : options,
      defaultOption,
    }
  }

  teamPermissionInfo(): BackendPermissionInfo<TeamMemberPermission> {
    const defaultOption = {
      label: 'Guest',
      value: [TeamMemberPermission.view],
    }

    const options = [
      defaultOption,
      {
        label: 'Member',
        value: [TeamMemberPermission.view, TeamMemberPermission.shareTo],
      },
      {
        label: 'Administrator',
        value: [TeamMemberPermission.view, TeamMemberPermission.shareTo, TeamMemberPermission.edit],
        isTopSeparated: true,
      },
    ]

    return {
      options,
      defaultOption,
    }
  }

  async fetchProjectInvites(projectId: string, opts: FetchMembersPaginationOptions): Promise<ResponseCollection<ProjectInvite>> {
    const params = new URLSearchParams()

    if (opts.filter) {
      params.set('search', opts.filter)
    }

    const { entries }: ResponseCollection<MiPasaProjectInvite> = await this.fetchJson(
      `${this.prefix}/v1/notebooks/${projectId}/invites?${params.toString()}`,
    )

    return { entries: entries.map(mapProjectInvite) }
  }

  async sendCreateProjectInvite(projectId: string, email: string, permissions: Array<NotebookPermission>): Promise<ProjectInvite> {
    const body = { email, permissions }

    const response: MiPasaProjectInvite = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/invites`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapProjectInvite(response)
  }

  async sendDeleteProjectInvite(projectId: string, inviteId: string): Promise<ProjectInvite> {
    const response: MiPasaProjectInvite = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/invites/${inviteId}`, { method: 'DELETE' })

    return mapProjectInvite(response)
  }

  async fetchTeamInvites(teamId: string, opts: FetchMembersPaginationOptions): Promise<ResponseCollection<TeamInvite>> {
    const params = new URLSearchParams()

    if (opts.filter) {
      params.set('search', opts.filter)
    }

    const { entries }: ResponseCollection<MiPasaTeamInvite> = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/invites?${params.toString()}`)

    return { entries: entries.map(mapTeamInvite) }
  }

  async sendCreateTeamInvite(teamId: string, email: string, permissions: Array<TeamMemberPermission>): Promise<TeamInvite> {
    const body = { email: email.toLowerCase(), permissions }

    const response: MiPasaTeamInvite = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/invites`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapTeamInvite(response)
  }

  async sendDeleteTeamInvite(teamId: string, inviteId: string): Promise<TeamInvite> {
    const response: MiPasaTeamInvite = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/invites/${inviteId}`, { method: 'DELETE' })

    return mapTeamInvite(response)
  }

  async sendCreateInvite(email: string, invitationMessage?: string): Promise<Invite> {
    const body = {
      email,
      invitation_message: invitationMessage,
    }

    const response: MiPasaInvite = await this.fetchJson(`${this.prefix}/v1/invites`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapInvite(response)
  }

  async fetchInvite(id: string): Promise<Invite> {
    const response: MiPasaInvite = await this.fetchJson(`${this.prefix}/v1/invites/${id}`)

    return mapInvite(response)
  }

  fetch(...params: Parameters<typeof authorizedFetch>) {
    const opts = params[1] || {}

    opts.signal = this.signal
    params[1] = opts

    return authorizedFetch(...params)
  }

  fetchText(...params: Parameters<typeof authorizedTextFetch>) {
    const opts = params[1] || {}

    opts.signal = this.signal
    params[1] = opts

    return authorizedTextFetch(...params)
  }

  fetchJson(...params: Parameters<typeof authorizedJsonFetch>) {
    const opts = params[1] || {}

    opts.signal = this.signal
    params[1] = opts

    return authorizedJsonFetch(...params)
  }

  withSignal(signal?: AbortSignal): Backend {
    const newBackend = Object.assign(Object.create(Object.getPrototypeOf(this)), this)

    newBackend.signal = signal
    return newBackend
  }

  async fetchWallet(opts: FetchWalletPaginationOptions): Promise<WalletState> {
    const params = new URLSearchParams()

    if (opts.perPage !== undefined) {
      params.set('page-size', opts.perPage.toString())
    }
    if (opts.page !== undefined) {
      params.set('page', opts.page.toString())
    }
    if (opts.filter && opts.filter.trim()) {
      params.set('search', opts.filter)
    }

    const response: MiPasaPaginatedResult<WalletTransaction> & {
      balance: number
      unbounded_balance: number | null
    } = await this.fetchJson(`${this.prefix}/v1/wallet?${params.toString()}`)

    return {
      balance: response.balance,
      unbounded_balance: response.unbounded_balance,
      entries: response.entries,
      page: response.page_number,
      perPage: response.page_size,
      totalEntries: response.total_entries,
      totalPages: response.total_pages,
    }
  }

  async sendTransferUnits(amount: number): Promise<WalletBalances> {
    return this.fetchJson(`${this.prefix}/v1/wallet/transfer`, {
      body: { amount },
      sendJson: true,
      method: 'POST',
    })
  }

  async fetchWalletUnitPurchaseInfo(): Promise<WalletUnitPurchaseInfo> {
    return this.fetchJson(`${this.prefix}/v1/wallet/get_unit_purchase_info`)
  }

  async sendCreateWalletUnitPurchaseSession(units: number): Promise<WalletUnitPurchaseSession> {
    return this.fetchJson(`${this.prefix}/v1/wallet/create_unit_purchase_session`, {
      body: { units },
      sendJson: true,
      method: 'POST',
    })
  }

  isDataRequestSupported(): boolean {
    return true
  }

  sendDataRequest(message: string): Promise<void> {
    return this.fetchJson(`${this.prefix}/v1/data_request`, {
      body: { message },
      sendJson: true,
      method: 'POST',
    })
  }

  sendUploadRequest(params?: FileUploadParams | undefined): Promise<FileUploadItem> {
    const body: { client_name?: string; client_mime_type?: string } = {}

    if (params?.clientName) {
      body.client_name = params.clientName
    }
    if (params?.clientMimeType) {
      body.client_mime_type = params.clientMimeType
    }

    return this.fetchJson(`${this.prefix}/v1/upload`, {
      body,
      sendJson: true,
      method: 'POST',
    })
  }

  async sendUploadContent(upload: FileUploadItem, content: string | ArrayBuffer | Blob): Promise<void> {
    await this.fetch(upload.url, {
      body: content,
      method: upload.method,
    })
  }

  isFollowersSupported(): boolean {
    return true
  }

  async fetchFollowers(userId: string, type: string, opts: PaginationOptions): Promise<PaginatedResult<UserFollow>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 100}`)
    params.set('page', `${opts?.page || 0}`)
    params.set('type', type)

    const response: MiPasaPaginatedResult<MiPasaUserFollow> = await this.fetchJson(`${this.prefix}/v1/users/${userId}/follows?${params.toString()}`)

    return {
      entries: response.entries.map(userFollow => mapUserFollow(userFollow)),
      page: response.page_number,
      totalPages: response.total_pages,
      perPage: response.page_size,
      totalEntries: response.total_entries,
    }
  }

  async sendFollowUser(userId: string): Promise<UserFollow> {
    const response: MiPasaUserFollow = await this.fetchJson(`${this.prefix}/v1/users/${userId}/follow`, { method: 'POST' })

    return mapUserFollow(response)
  }

  async sendUnfollowUser(userId: string): Promise<UserFollow> {
    const response: MiPasaUserFollow = await this.fetchJson(`${this.prefix}/v1/users/${userId}/follow`, { method: 'DELETE' })

    return mapUserFollow(response)
  }

  async fetchGitHubConnection(): Promise<GitHubConnection> {
    const response: MiPasaGitHubConnection = await this.fetchJson(`${this.prefix}/v1/github`)

    return mapGitHubConnection(response)
  }

  async fetchBinanceConnection(): Promise<BinanceConnection> {
    const response = await this.fetchJson(`${this.prefix}/v1/connections/binance`)
    const connection = mapBinanceConnection(response)
    return connection
  }

  async sendConnectToBinance(key: string, secret: string): Promise<BinanceConnection> {
    const body = {
      key,
      secret,
    }

    const response: MiPasaBinanceConnection = await this.fetchJson(`${this.prefix}/v1/connections/binance`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapBinanceConnection(response)
  }

  async sendDisconnectFromBinance(): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/connections/binance`, { method: 'DELETE' })
  }

  async sendConnectToGitHub(code: string, redirectUri: string): Promise<GitHubConnection> {
    const body = {
      code,
      redirect_uri: redirectUri,
    }

    const response: MiPasaGitHubConnection = await this.fetchJson(`${this.prefix}/v1/github`, {
      method: 'POST',
      sendJson: true,
      body,
    })

    return mapGitHubConnection(response)
  }

  async sendDisconnectFromGitHub(): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/github`, { method: 'DELETE' })
  }

  async fetchRatePlans(): Promise<Array<RatePlan>> {
    const { entries }: { entries: Array<MiPasaRatePlan> } = await this.fetchJson(`${this.prefix}/v1/rate_plans`)

    return entries.map(mapRatePlan)
  }

  async syncProject(projectId: string, syncConfigId: string, action: string, opts?: SyncProjectOpts) {
    const response: MiPasaProjectSyncResponse = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/sync_configs/${syncConfigId}/sync`, {
      method: 'POST',
      sendJson: true,
      body: { mode: action, prefer: opts?.prefer },
    })

    return mapProjectSyncResponse(response)
  }

  async sendCreateProjectSyncConfig(projectId: string, params: CreateProjectSyncConfigParams): Promise<ProjectSyncResponse> {
    let mappedParams: any = {}

    if (params.mode === 'existing') {
      params = params as CreateExistingProjectSyncConfigParams

      mappedParams = {
        mode: 'existing',
        provider: params.provider,
        repo: params.repo,
        branch: params.branch,
      }

      if (params.providerConfig) {
        mappedParams.provider_config = {}
        if (params.providerConfig.accessToken) mappedParams.provider_config.access_token = params.providerConfig.accessToken
        if (params.providerConfig.instanceUrl) mappedParams.provider_config.instance_url = params.providerConfig.instanceUrl
      }
      if (params.directory) mappedParams.directory = params.directory
      if (params.conflictsPrefer) mappedParams.prefer = params.conflictsPrefer
    } else {
      params = params as CreateNewProjectSyncConfigParams

      mappedParams = {
        mode: 'new',
        repo: params.repoName,
      }

      if (params.providerConfig?.accessToken) mappedParams.access_token = params.providerConfig.accessToken
      if (params.private !== undefined) mappedParams.private = params.private
    }

    const response = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/sync_configs`, {
      method: 'POST',
      sendJson: true,
      body: mappedParams,
    })

    return mapProjectSyncResponse(response)
  }

  async sendDeleteProjectSyncConfig(projectId: string, syncConfigId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/sync_configs/${syncConfigId}`, { method: 'DELETE' })
  }

  async fetchBrowseSyncRemote(projectId: string, params: BrowseSyncRemoteParams): Promise<BrowseSyncRemoteResponse> {
    const mappedParams = new URLSearchParams()

    mappedParams.set('provider', params.provider)
    if (params.providerConfig) {
      if (params.providerConfig.accessToken) mappedParams.set('provider_config[access_token]', params.providerConfig.accessToken)
      if (params.providerConfig.instanceUrl) mappedParams.set('provider_config[instance_url]', params.providerConfig.instanceUrl)
    }
    if (params.repo) mappedParams.set('repo', params.repo)
    if (params.branch) mappedParams.set('branch', params.branch)
    if (params.directory) mappedParams.set('directory', params.directory)

    const response = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/sync/browse_remote?${mappedParams.toString()}`)

    return response
  }

  fetchExportProjectURL(projectId: string, fileIds?: string[]): string {
    const urlParams = new URLSearchParams()

    fileIds?.forEach(id => urlParams.append('file_ids[]', id))

    return `${this.prefix}/v1/projects/${projectId}/export?${urlParams.toString()}`
  }

  fetchExportProjectFileURL(projectId: string, fileId: string, opts?: ExportProjectFileOpts): string {
    const params = new URLSearchParams()

    if (opts?.includeOutputs) {
      params.set('outputs', '1')
    }

    if (opts?.printTo) {
      params.set('print_to', opts.printTo)
    }

    if (opts?.printTheme) {
      params.set('print_theme', opts.printTheme)
    }

    if (opts?.printWidth) {
      params.set('print_width', opts.printWidth.toString())
    }

    if (opts?.layoutHeaderText) {
      params.set('layout_header_text', opts.layoutHeaderText.toString())
    }

    if (opts?.layoutHeaderDate) {
      params.set('layout_header_date', 'true')
    }

    if (opts?.layoutFooterText) {
      params.set('layout_footer_text', opts.layoutFooterText.toString())
    }

    return `${this.prefix}/v1/projects/${projectId}/files/${fileId}/export?${params.toString()}`
  }

  async fetchExportProjectFile(projectId: string, fileId: string, opts?: ExportProjectFileOpts): Promise<Blob> {
    const url = this.fetchExportProjectFileURL(projectId, fileId, opts)
    const response = await this.fetch(url)
    return response.blob()
  }

  async fetchNotifications(opts: NotificationsParams): Promise<PaginatedResult<NotificationEntry>> {
    const params = new URLSearchParams()

    params.set('page-size', `${opts?.perPage || 10}`)
    params.set('page', `${opts?.page || 0}`)

    if (opts.seen !== undefined) {
      params.set('seen', `${opts.seen}`)
    }

    opts.type?.forEach(t => params.append('type[]', t))
    opts.typeExclude?.forEach(t => params.append('type.exclude[]', t))

    const { total_entries, page_size, total_pages, page_number, entries }: MiPasaPaginatedResult<MiPasaNotificationEntry> = await this.fetchJson(
      `${this.prefix}/v1/notifications?${params.toString()}`,
    )

    return {
      totalEntries: total_entries,
      perPage: page_size,
      totalPages: total_pages,
      page: page_number,
      entries: entries.map(mapNotificationEntry),
    }
  }

  async sendMarkNotificationsAsRead(): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/notifications/mark_all_as_read`, { method: 'POST' })
  }

  async fetchTagSubscriptions(): Promise<ResponseCollection<TagSubscription>> {
    return this.fetchJson(`${this.prefix}/v1/tag_subscriptions`)
  }

  async sendSubscribeToTag(tagId: string, email: string): Promise<TagSubscription> {
    const body = { tag_id: tagId, email }

    return this.fetchJson(`${this.prefix}/v1/tag_subscriptions`, {
      method: 'POST',
      sendJson: true,
      body,
    })
  }

  async sendUnsubscribeFromTag(subscriptionId: string): Promise<TagSubscription> {
    return this.fetchJson(`${this.prefix}/v1/tag_subscriptions/${subscriptionId}`, { method: 'DELETE' })
  }

  async sendAIGenerate(query: string, type: AIGenerationType): Promise<Array<AIGeneratedEntry>> {
    const { entries }: { entries: Array<AIGeneratedEntry> } = await this.fetchJson(`${this.prefix}/v1/ai/generate`, {
      method: 'POST',
      body: { query, type },
      sendJson: true,
    })

    return entries
  }

  async sendAIGenerateSQL(query: string, databaseType: string, opts: AIGenerateSQLOpts): Promise<AIGeneratedCode> {
    return this.fetchJson(`${this.prefix}/v1/ai/sql`, {
      method: 'POST',
      body: {
        query,
        type: databaseType,
        database: opts.databaseName,
        table_information: opts.databaseTables,
        multiple: opts.allowMultipleTables,
        snowflake_schema: opts.snowflakeSchema,
      },
      sendJson: true,
    })
  }

  async sendAIGenerateChart(query: string, dataTypeInformation: string, variableName: string): Promise<AIGeneratedCode> {
    return this.fetchJson(`${this.prefix}/v1/ai/chart`, {
      method: 'POST',
      body: {
        query,
        dtype_information: dataTypeInformation,
        variable_name: variableName,
      },
      sendJson: true,
    })
  }

  async sendAIGeneratePublicationsQuery(query: string, mode: ViewMode = ViewMode.public): Promise<AIGeneratedPublicationsQuery> {
    let access_level: PublicationsAccessLevel

    switch (mode) {
      case ViewMode.shared:
        access_level = PublicationsAccessLevel.collaborator_shared
        break
      case ViewMode.private:
        access_level = PublicationsAccessLevel.link_shared
        break
      default:
        access_level = PublicationsAccessLevel.public
        break
    }

    return this.fetchJson(`${this.prefix}/v1/ai/publications-query`, {
      method: 'POST',
      body: {
        query,
        access_level,
      },
      sendJson: true,
    })
  }

  async sendCreateTradingTransaction(template: Partial<TradingTransaction>): Promise<TradingTransaction> {
    return this.fetchJson(`${this.prefix}/v1/trading/transactions`, {
      method: 'POST',
      sendJson: true,
      body: template,
    })
  }

  async fetchTradingTransaction(id: string): Promise<TradingTransaction> {
    return this.fetchJson(`${this.prefix}/v1/trading/transactions/${id}`)
  }

  async sendUpdateTeamOwner(teamId: string, userId: string): Promise<Team> {
    const body = { user_id: userId }

    const team: MiPasaTeam = await this.fetchJson(`${this.prefix}/v1/teams/${teamId}/owner`, {
      method: 'PATCH',
      sendJson: true,
      body,
    })

    return mapTeam(team)
  }

  async sendAddPublicationClap(fileId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/publications/files/${fileId}/clap`, { method: 'POST' })
  }

  async sendRemovePublicationClap(fileId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/publications/files/${fileId}/clap`, { method: 'DELETE' })
  }

  async adminFetchActivity(opts: AdminFetchActivityOptions): Promise<PaginatedResult<AdminActivityLog>> {
    if (opts.types !== undefined && opts.types.length === 0) {
      return {
        page: 1,
        perPage: opts.perPage || 20,
        entries: [],
        totalEntries: 0,
        totalPages: 1,
      }
    }

    const params = new URLSearchParams()

    opts.page && params.set('page', String(opts.page))
    opts.perPage && params.set('page-size', String(opts.perPage))
    opts.search && params.set('search', String(opts.search))
    opts.allUsers && params.set('all_users', '1')
    params.set('anonymous', opts.anonymous ? '1' : '0')

    if (opts.userName) {
      params.set('user_name', opts.userName)
    }

    opts.types?.forEach(type => params.append('type[]', type))

    const log: MiPasaPaginatedResult<AdminMiPasaActivityLog> = await this.fetchJson(`${this.prefix}/v1/admin/tracking?${params.toString()}`)

    return mapPaginatedResponse(log, mapAdminActivityLog)
  }

  async adminFetchUser(userId: string): Promise<AdminUser> {
    const user: AdminMiPasaUser = await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}`)

    return mapAdminUser(user)
  }

  async adminFetchUsers(opts: AdminFetchUsersOptions): Promise<PaginatedResult<AdminUser>> {
    const params = new URLSearchParams()

    opts.page && params.set('page', String(opts.page))
    opts.perPage && params.set('page-size', String(opts.perPage))
    opts.search && params.set('search', String(opts.search))
    opts.role && params.set('role', String(opts.role))
    opts.roleId && params.set('role_id', String(opts.roleId))
    opts.planId && params.set('plan_id', String(opts.planId))
    opts.status && params.set('status', String(opts.status))
    opts.sortingField && params.set('sort_by', String(opts.sortingField))
    opts.sortingDirection && params.set('sort_direction', String(opts.sortingDirection))

    const users: MiPasaPaginatedResult<AdminMiPasaUser> = await this.fetchJson(`${this.prefix}/v1/admin/users?${params.toString()}`)

    return mapPaginatedResponse(users, mapAdminUser)
  }

  async adminFetchFeatures(): Promise<AdminFeature[]> {
    const response = await this.fetchJson(`${this.prefix}/v1/admin/features`)
    return response.entries.map((key: string) => ({ key }))
  }

  async adminFetchRoles(): Promise<AdminRole[]> {
    const response = await this.fetchJson(`${this.prefix}/v1/admin/roles`)
    return response.entries
  }

  async adminSendCreateRole(role: AdminRole): Promise<AdminRole> {
    const response = await this.fetchJson(`${this.prefix}/v1/admin/roles`, {
      method: 'POST',
      body: { role },
      sendJson: true,
    })

    return response.role
  }

  async adminSendUpdateRole(role: AdminRole): Promise<AdminRole> {
    const response = await this.fetchJson(`${this.prefix}/v1/admin/roles/${role.id}`, {
      method: 'PATCH',
      body: { role },
      sendJson: true,
    })

    return response.role
  }

  async adminSendDeleteRole(role: AdminRole): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/admin/roles/${role.id}`, { method: 'DELETE' })
  }

  async adminFetchUserRoles(userId: string): Promise<AdminRole[]> {
    const response = await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}/roles`)
    return response.entries
  }

  async adminSendAddUserRole(userId: string, roleId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}/roles`, {
      method: 'POST',
      body: { role_id: roleId },
      sendJson: true,
    })
  }

  async adminSendRemoveUserRole(userId: string, roleId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}/roles/${roleId}`, { method: 'DELETE' })
  }

  async adminSendUserRole(userId: string, role: AdminUserRole): Promise<AdminUser> {
    const user: AdminMiPasaUser = await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}/access`, {
      method: 'PUT',
      body: { role },
      sendJson: true,
    })

    return mapAdminUser(user)
  }

  async adminSendUserSubscription(userId: string, planId: string): Promise<AdminUser> {
    const user: AdminMiPasaUser = await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}/subscription`, {
      method: 'PUT',
      body: { plan_id: planId },
      sendJson: true,
    })

    return mapAdminUser(user)
  }

  async adminFetchUserWalletTransactions(userId: string, opts: PaginationOptions): Promise<PaginatedResult<AdminWalletTransaction>> {
    const params = new URLSearchParams()

    opts.page && params.set('page', String(opts.page))
    opts.perPage && params.set('page-size', String(opts.perPage))

    const result: MiPasaPaginatedResult<AdminMiPasaTransaction> = await this.fetchJson(
      `${this.prefix}/v1/admin/users/${userId}/wallet/transactions?${params.toString()}`,
    )

    return mapPaginatedResponse(result, mapAdminTransaction)
  }

  async adminSendUserWalletTransaction(userId: string, params: AdminWalletTransactionParams): Promise<AdminWalletTransaction> {
    const mipasaParams = {
      kind: params.kind,
      type: params.type,
      amount_in_units: params.amount,
    }

    const result: AdminMiPasaTransaction = await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}/wallet/transactions`, {
      method: 'POST',
      body: { transaction: mipasaParams },
      sendJson: true,
    })

    return mapAdminTransaction(result)
  }

  async adminSendImpersonateUser(userId: string): Promise<Self> {
    const miPasaSelf: MiPasaSelf = await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}/mask`, {
      method: 'POST',
    })

    const [preferences, gitHub]: [UserPreferences, MiPasaGitHubConnection] = await Promise.all([
      this.fetchJson(`${this.prefix}/v1/preferences`),
      this.fetchJson(`${this.prefix}/v1/github`),
    ])

    const self = mapSelf(miPasaSelf, preferences, gitHub)

    this.self = self

    return self
  }

  async adminSendUnimpersonate(): Promise<Self> {
    const miPasaSelf: MiPasaSelf = await this.fetchJson(`${this.prefix}/v1/admin/unmask`, {
      method: 'DELETE',
    })

    const [preferences, gitHub]: [UserPreferences, MiPasaGitHubConnection] = await Promise.all([
      this.fetchJson(`${this.prefix}/v1/preferences`),
      this.fetchJson(`${this.prefix}/v1/github`),
    ])

    const self = mapSelf(miPasaSelf, preferences, gitHub)

    this.self = self

    return self
  }

  async adminSendSuspend(userId: string, message: string): Promise<AdminUser> {
    const params = new URLSearchParams()

    params.set('message', message)

    const user: AdminMiPasaUser = await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}/suspend?${params.toString()}`, {
      method: 'POST',
    })

    return mapAdminUser(user)
  }

  async adminSendUnsuspend(userId: string, message: string): Promise<AdminUser> {
    const params = new URLSearchParams()

    params.set('message', message)

    const user: AdminMiPasaUser = await this.fetchJson(`${this.prefix}/v1/admin/users/${userId}/unsuspend?${params.toString()}`, {
      method: 'POST',
    })

    return mapAdminUser(user)
  }

  async adminFetchUserActionLogs(opts: AdminFetchUserActionLogsOption): Promise<PaginatedResult<AdminUserActionLog>> {
    const params = new URLSearchParams()

    params.set('page', String(opts.page || 1))
    params.set('page-size', String(opts.perPage || 10))

    const result: MiPasaPaginatedResult<AdminMiPasaActionLog> = await this.fetchJson(
      `${this.prefix}/v1/admin/users/${opts.userId}/action_logs?${params.toString()}`,
    )

    return mapPaginatedResponse(result, mapAdminActionLog)
  }

  async adminFetchSubsctiptionStats(opts: AdminFetchSubStats): Promise<AdminSubscriptionStatsResult> {
    const params = new URLSearchParams()

    params.set('page', String(opts.page || 1))
    params.set('page-size', String(opts.perPage || 10))
    if (opts.search) {
      params.set('search', opts.search)
    }

    const result: MiPasaAdminSubscriptionStatsResult = await this.fetchJson(`${this.prefix}/v1/admin/subscriptions?${params.toString()}`)

    return {
      ...mapPaginatedResponse(result, mapAdminSubscriptionStat),
      totalViews: result.total_views,
      totalSubs: result.total_subs,
    }
  }

  async fetchExternalPublicationSettings(fileId: string): Promise<ExternalPublishSettings> {
    const mipasaSettings: MiPasaExternalPublishSettings = await this.fetchJson(`${this.prefix}/v1/external_publications/${fileId}`)
    return mapExternalPublicationSettings(mipasaSettings)
  }

  async sendUpdateExternalPublicationSettings(fileId: string, update: ExternalPublishSettings): Promise<ExternalPublishSettings> {
    const publication: MiPasaExternalPublishSettings = {
      enabled: update.enabled,
      cell_ids: update.cellIds,
      title: update.title,
      print_format: update.printFormat,
      print_theme: update.printTheme,
      print_width: update.printWidth,
      layout_header_text: update.layoutHeaderText,
      layout_header_date: update.layoutHeaderDate,
      layout_footer_text: update.layoutFooterText,
      slack_settings: {
        enabled: Boolean(update.slackSettings?.enabled),
        channel_id: update.slackSettings?.channelId,
        channel_name: update.slackSettings?.channelName,
      },
      email_settings: update.emailSettings,
    }

    const mipasaSettings: MiPasaExternalPublishSettings = await this.fetchJson(`${this.prefix}/v1/external_publications/${fileId}`, {
      method: 'PATCH',
      sendJson: true,
      body: { publication },
    })
    return mapExternalPublicationSettings(mipasaSettings)
  }

  fetchFileEmbed(projectId: string, fileId: string): Promise<FileEmbed>
  fetchFileEmbed(embedId: string): Promise<FileEmbed>
  async fetchFileEmbed(projectId: string, fileId?: string): Promise<FileEmbed> {
    if (!fileId) {
      const embedId = projectId
      const miPasaFileEmbed: MiPasaFileEmbed = await this.fetchJson(`${this.prefix}/v1/file_embed/${embedId}`)
      return mapFileEmbed(miPasaFileEmbed)
    }

    const miPasaFileEmbed: MiPasaFileEmbed = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/files/${fileId}/embed`)
    return mapFileEmbed(miPasaFileEmbed)
  }

  async sendUpdateFileEmbed(projectId: string, fileId: string, opts: FileEmbedOptions): Promise<FileEmbed> {
    const miPasaOpts: MiPasaFileEmbedOptions = {
      execution_enabled: opts.isExecutionEnabled,
      execution_copy_project_files: opts.isFileCopyEnabled,
    }

    const miPasaFileEmbed: MiPasaFileEmbed = await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/files/${fileId}/embed`, {
      method: 'PATCH',
      sendJson: true,
      body: miPasaOpts,
    })
    return mapFileEmbed(miPasaFileEmbed)
  }

  async sendRevokeFileEmbed(projectId: string, fileId: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/notebooks/${projectId}/files/${fileId}/embed`, { method: 'DELETE' })
  }

  async fetchPublicationSchedule(fileId: string): Promise<PublicationSchedule> {
    const mipasaSchedule: MiPasaPublicationSchedule = await this.fetchJson(`${this.prefix}/v1/publication_schedules/${fileId}`)
    return mapPublicationSchedule(mipasaSchedule)
  }

  async sendUpdatePublicationSchedule(fileId: string, update: PublicationSchedule): Promise<PublicationSchedule> {
    const t = update.type === PublicationScheduleType.oneOff ? 'one_off' : 'recurring'
    const schedule: MiPasaPublicationSchedule = {
      type: t,
      enabled: update.enabled,
      starts_at: update.startsAt ? update.startsAt.toISOString() : undefined,
      interval_type: update.intervalType,
      interval_value: update.intervalValue,
      allowed_days: update.allowedDays,
    }

    const mipasaSchedule: MiPasaPublicationSchedule = await this.fetchJson(`${this.prefix}/v1/publication_schedules/${fileId}`, {
      method: 'PATCH',
      sendJson: true,
      body: { schedule },
    })
    return mapPublicationSchedule(mipasaSchedule)
  }

  async fetchReferrals(): Promise<Array<Referral>> {
    return this.fetchJson(`${this.prefix}/v1/admin/referrals`)
  }

  async fetchReferralUsers(referralId: string, opts: PaginationOptions): Promise<PaginatedResult<ReferralUser>> {
    const params = createPaginationParams(opts)
    const result: MiPasaPaginatedResult<MiPasaAdminReferralUser> = await this.fetchJson(
      `${this.prefix}/v1/admin/referrals/${referralId}/users?${params.toString()}`,
    )
    const entries: ReferralUser[] = result.entries.map(e => ({
      id: e.id,
      firstName: e.first_name,
      lastName: e.last_name,
      userName: e.user_name,
      insertedAt: new Date(e.inserted_at),
    }))

    return {
      entries,
      page: result.page_number,
      perPage: result.page_size,
      totalPages: result.total_pages,
      totalEntries: result.total_entries,
    }
  }

  async sendCreateReferral(referral: ReferralUpdate): Promise<Referral> {
    return this.fetchJson(`${this.prefix}/v1/admin/referrals`, {
      method: 'POST',
      sendJson: true,
      body: referral,
    })
  }

  async sendUpdateReferral(id: string, update: Partial<ReferralUpdate>): Promise<Referral> {
    return this.fetchJson(`${this.prefix}/v1/admin/referrals/${id}`, {
      method: 'PATCH',
      sendJson: true,
      body: update,
    })
  }

  async sendDeleteReferral(id: string): Promise<void> {
    await this.fetchJson(`${this.prefix}/v1/admin/referrals/${id}`, { method: 'DELETE' })
  }

  generateInstanceExportURL(opts: InstanceExportOpts): string {
    const params = new URLSearchParams()
    params.set('instance_id', opts.instanceId)
    params.set('cell_id', opts.cellId)
    params.set('output_index', String(opts.outputIndex))
    params.set('file_name', opts.fileName)
    opts.saveInProject && params.set('save_in_project', 'true')
    return `${this.prefix}/v1/notebooks/export_cell?${params.toString()}`
  }

  async sendSaveInstanceExport(opts: InstanceExportOpts): Promise<void> {
    const url = this.generateInstanceExportURL(opts)
    await this.fetchJson(url)
  }

  async fetchTradingAssets(opts?: TradingAssetsOpts): Promise<TradingAsset[]> {
    const params = new URLSearchParams()

    if (opts?.remoteType) {
      params.set('remote_type', opts.remoteType)
    }

    if (opts?.includeRemoteIds) {
      opts.includeRemoteIds.forEach(id => params.append('include_remote_ids[]', id))
    }

    if (opts?.search) {
      params.set('search', opts.search)
    }

    const response = await this.fetchJson(`${this.prefix}/v1/trading_assets?${params.toString()}`)

    return response.map(mapTradingAsset)
  }

  async fetchTradingAsset(opts: TradingAssetDetailsOpts): Promise<TradingAsset> {
    const params = new URLSearchParams()

    params.set('remote_id', opts.remoteId)
    params.set('remote_type', opts.remoteType || 'coingecko')

    const rawAsset = await this.fetchJson(`${this.prefix}/v1/trading_assets/info?${params.toString()}`)

    return mapTradingAsset(rawAsset)
  }

  async fetchTradingAssetData(opts: TradingAssetChartOpts): Promise<TradingAssetData> {
    const params = new URLSearchParams()

    params.set('remote_id', opts.remoteId)
    params.set('remote_type', opts.remoteType || 'coingecko')
    params.set('interval', opts.interval || 'daily')

    const rawData = await this.fetchJson(`${this.prefix}/v1/trading_assets/chart?${params.toString()}`)

    return mapTradingAssetData(rawData)
  }

  async fetchTradingBalance(opts: TradingExchangeOpts): Promise<TradingBalance[]> {
    const params = new URLSearchParams()

    params.set('exchange', opts.exchange)

    const response = await this.fetchJson(`${this.prefix}/v1/trading/balance?${params.toString()}`)
    return response
  }

  async fetchTradingDemoMarkets(): Promise<Dictionary<Market>> {
    const response = await this.fetchJson(`${this.prefix}/v1/trading/demo/markets`)
    return response
  }

  async fetchTradingDemoTickers(): Promise<Tickers> {
    const response = await this.fetchJson(`${this.prefix}/v1/trading/demo/tickers`)
    return response
  }

  async adminFetchTradingBotChannels(): Promise<TradingBotChannel[]> {
    const channels: MiPasaTradingBotChannel[] = await this.fetchJson(`${this.prefix}/v1/admin/trading_bot_channels`)

    return channels.map(mapTradingBotChannel)
  }

  async adminSendUpdateTradingBotChannel(channelId: string, attrs: Partial<TradingBotChannel>): Promise<TradingBotChannel> {
    const body: Partial<MiPasaTradingBotChannel> = {}

    if (attrs.periodicUpdatesEnabled !== undefined) {
      body.periodic_updates_enabled = attrs.periodicUpdatesEnabled
    }

    const channel = await this.fetchJson(`${this.prefix}/v1/admin/trading_bot_channels/${channelId}`, {
      method: 'PATCH',
      sendJson: true,
      body,
    })

    return mapTradingBotChannel(channel)
  }

  async adminSendSendTradingBotChannelPeriodicUpdate(channelId: string): Promise<TradingBotChannel> {
    const channel = await this.fetchJson(`${this.prefix}/v1/admin/trading_bot_channels/${channelId}/send_periodic_update`, {
      method: 'POST',
    })

    return mapTradingBotChannel(channel)
  }

  async adminSendRefreshTradingBotChannel(channelId: string): Promise<TradingBotChannel> {
    const channel = await this.fetchJson(`${this.prefix}/v1/admin/trading_bot_channels/${channelId}/refresh`, {
      method: 'POST',
    })

    return mapTradingBotChannel(channel)
  }

  async adminSendDeleteTradingBotChannel(channelId: string): Promise<TradingBotChannel> {
    const channel = await this.fetchJson(`${this.prefix}/v1/admin/trading_bot_channels/${channelId}`, {
      method: 'DELETE',
    })

    return mapTradingBotChannel(channel)
  }

  async adminFetchWatermarkUrl(theme: AdminWatermarkTheme): Promise<string> {
    return `${this.prefix}/v1/admin/watermark/${theme}`
  }

  async adminSendWatermark(theme: AdminWatermarkTheme, watermarkFileData: Blob): Promise<void> {
    const data = new FormData()
    data.append('image', watermarkFileData)

    await this.fetch(await this.adminFetchWatermarkUrl(theme), { method: 'PUT', body: data })
  }

  async adminDeleteWatermark(theme: AdminWatermarkTheme): Promise<void> {
    await this.fetch(await this.adminFetchWatermarkUrl(theme), { method: 'DELETE' })
  }
}
